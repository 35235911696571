import React, { KeyboardEvent, MouseEvent, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import './cdt-color-bar.scss';
import { FavoritesContext, FavoritesDispatchContext } from '../../../../shared/context/favorite.context';
import { SelectedColorContext, SelectedColorDispatchContext } from '../../../../shared/context/selectedColor.context';
import { Link, useNavigate, useParams } from 'react-router-dom';
import type { AuthoredDataT, CartAPIFavoriteT, ColorsT } from '../../../../shared/types/types';
import { useProjectContext } from '../../../../shared/context/project.context';
import CdtClearFavoritePopup from '../cdt-clear-favorite-popup/cdt-clear-favorite-popup';

interface CdtColorBarProps {
  authoredData: AuthoredDataT;
  colors: ColorsT[];
}

interface FavoriteT {
  index: number;
  to: string;
  color: ColorsT | null;
  isSelected: boolean;
  clickHandler: (e: MouseEvent, index: number, mdmId: string) => void;
  keyDownHandler: (e: KeyboardEvent, index: number, mdmId: string) => boolean;
}

const findColor = (haystack: ColorsT[], needle: CartAPIFavoriteT | null) => {
  return haystack.find((hay) => hay.mdmId === needle?.mdmId || hay.mdmId === needle?.mdmColorId) ?? null;
};

const CdtColorBar: React.FC<CdtColorBarProps> = ({ authoredData, colors }): ReactElement => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [popupVisible, setPopupVisible] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [project] = useProjectContext() ?? [];
  const [showBar, setShowBar] = useState(false);
  const colorBarRef = useRef<HTMLDivElement | null>(null);
  const editRef = useRef<HTMLButtonElement | null>(null);
  const clearAllRef = useRef<HTMLButtonElement | null>(null);
  const doneRef = useRef<HTMLButtonElement | null>(null);
  const dispatch = useContext(FavoritesDispatchContext);
  const favorites = useContext(FavoritesContext);
  const selectedColor = useContext(SelectedColorContext);
  const selectedColorDispatch = useContext(SelectedColorDispatchContext);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setMode(favorites.length > 0 && mode === 'edit' ? 'edit' : 'view');
    setShowBar(favorites.length > 0);
    const intervalId = setInterval(() => window.dispatchEvent(new Event('resize')), 10);
    setTimeout(() => clearInterval(intervalId), 600);
  }, [favorites, mode]);

  const handleEditClick = () => {
    setMode('edit');
    setTimeout(() => doneRef.current?.focus(), 100);
  };
  const handleClearAll = () => {
    setPopupVisible(true);
  };

  const setNextPosition = (colorElement: HTMLAnchorElement) => {
    colorElement.focus();
    const found = colors.find((color) => color.mdmId === colorElement.dataset.colorMdmId);
    if (found) {
      if (params.mdmId) {
        navigate(
          `/${authoredData?.colorDetailTitle?.toLowerCase().replaceAll(' ', '-')}/${projectName}/${found.mdmId}`,
        );
      } else if (selectedColorDispatch) {
        selectedColorDispatch({ type: 'ADD_SELECTED_COLOR', color: found });
      }
    }
  };

  const handleRemoveFavorite = (index: number, mdmId: string) => {
    const colorElements = colorBarRef.current?.querySelectorAll<HTMLAnchorElement>('.favoriteColor');
    if (!dispatch) return;
    dispatch({
      type: 'remove_favorite',
      favID: mdmId,
    });
    if (colorElements) {
      if (index + 1 >= colorElements.length) {
        const colorElement = colorElements.item(index - 1);
        setNextPosition(colorElement);
      } else {
        const colorElement = colorElements.item(index + 1);
        setNextPosition(colorElement);
      }
    }
  };

  const handleDoneEditing = () => {
    setMode('view');
    setShowBar(favorites.length > 0);
    setTimeout(() => editRef.current?.focus(), 100);
  };

  const clickHandler = (e: MouseEvent, index: number, mdmId: string) => {
    if (mdmId) {
      if (mode === 'edit') {
        e.preventDefault();
        handleRemoveFavorite(index, mdmId);
      }
    }
  };

  const keyDownHandler = (e: KeyboardEvent, index: number, mdmId: string) => {
    if (mdmId) {
      const colorElements = colorBarRef.current?.querySelectorAll<HTMLAnchorElement>('.favoriteColor');
      switch (e.code) {
        case 'Enter':
          if (mode === 'edit') {
            e.preventDefault();
            handleRemoveFavorite(index, mdmId);
            return false;
          }
          break;
        case 'ArrowRight': {
          if (colorElements) {
            if (index + 1 < colorElements.length) {
              colorElements.item(index + 1).focus();
            } else {
              clearAllRef.current?.focus();
            }
          }
          break;
        }
        case 'ArrowLeft': {
          if (colorElements) {
            if (index - 1 > -1 && index - 1 < colorElements.length) {
              colorElements.item(index - 1).focus();
            }
          }
          break;
        }
      }
    }
    return true;
  };

  // Tab keyboard event has to be on keyDown or the default behavior cannot be circumvented
  const handleOnKeyDown = (e: KeyboardEvent) => {
    e.preventDefault();
    const element = e.target as HTMLButtonElement;
    if (element.className.includes('clear-all-button')) {
      switch (e.code) {
        case 'Enter':
          handleClearAll();
          break;
        case 'Tab':
          doneRef.current?.focus();
          break;
        case 'ArrowLeft':
          if (favorites.length > 0) {
            document
              .querySelector<HTMLAnchorElement>('.cdt-color-bar-fav-container .favoriteColor:last-of-type')
              ?.focus();
          }
          break;
        case 'ArrowRight':
          doneRef.current?.focus();
          break;
      }
    }
    if (element.className.includes('done-editing-button')) {
      switch (e.code) {
        case 'Enter':
          handleDoneEditing();
          break;
        case 'Tab':
          if (favorites.length > 0 && selectedColor) {
            document
              .querySelector<HTMLButtonElement>('.cdt-color-bar-fav-container .favoriteColor.favoriteColor--selected')
              ?.focus();
          } else {
            clearAllRef.current?.focus();
          }
          break;
        case 'ArrowLeft':
          clearAllRef.current?.focus();
          break;
      }
    }
  };

  useEffect(() => {
    setProjectName(
      project?.itemTitle?.toLowerCase() ?? authoredData?.itemSelector[0].itemTitle?.toLowerCase() ?? 'dresser',
    );
  }, [authoredData?.itemSelector, project?.itemTitle]);

  if (authoredData.hideFavorites) return <></>;

  return (
    <>
      <div className={`cdt-color-bar cdt-color-bar--spacer ${mode} ${showBar ? 'show' : 'hide'}`} />
      <div className={`cdt-color-bar ${mode} ${showBar ? 'show' : 'hide'}`}>
        <div className='cdt-color-bar-fav-container' ref={colorBarRef}>
          {(favorites ?? []).map((favorite, index) => {
            const color = findColor(colors, favorite);
            const to = `/${authoredData?.colorDetailTitle
              ?.toLowerCase()
              .replaceAll(' ', '-')}/${projectName}/${color?.mdmId}`;
            return (
              <FavoriteColor
                index={index}
                to={to}
                color={color}
                isSelected={color?.mdmId === selectedColor?.mdmId}
                clickHandler={clickHandler}
                keyDownHandler={keyDownHandler}
                key={index}
              />
            );
          })}
          <button
            aria-label={authoredData.colorBarEditAriaLabel ?? ''}
            className='viewing'
            onClick={handleEditClick}
            ref={editRef}
            type='button'
          >
            <span>{authoredData.colorBarEditLabel}</span>
          </button>
          <button
            aria-label={authoredData.colorBarClearAriaLabel ?? ''}
            className='editing clear-all-button'
            onClick={handleClearAll}
            onKeyDown={handleOnKeyDown}
            ref={clearAllRef}
            type='button'
          >
            <span>{authoredData.colorBarClearLabel}</span>
          </button>
          <button
            aria-label={authoredData.colorBarDoneAriaLabel ?? ''}
            className='editing done-editing-button'
            onClick={handleDoneEditing}
            onKeyDown={handleOnKeyDown}
            ref={doneRef}
            type='button'
          >
            <span>{authoredData.colorBarDoneLabel}</span>
          </button>
        </div>
      </div>
      <CdtClearFavoritePopup authoredData={authoredData} popupState={[popupVisible, setPopupVisible]} />
    </>
  );
};
export default CdtColorBar;

function FavoriteColor({ index, to, color, isSelected, clickHandler, keyDownHandler }: FavoriteT): ReactElement {
  if (!color) return <></>;
  const backgroundImage = `url(${color.associatedAssets.find((aa) => aa.type === 'ATT_Carousel_Image_01')?.url})`;
  return (
    <Link
      className={`favoriteColor ${isSelected ? 'favoriteColor--selected' : ''}`}
      to={to}
      onClick={(e) => clickHandler(e, index, color?.mdmId)}
      onKeyDown={(e) => keyDownHandler(e, index, color?.mdmId)}
      tabIndex={isSelected ? 0 : -1}
      data-color-mdm-id={color.mdmId}
    >
      <span className='favoriteColor__bg-image' style={{ backgroundImage: backgroundImage }} />
      <span className='favoriteColor__deleteButton' />
    </Link>
  );
}
