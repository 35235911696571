import type { KeyboardEvent, MouseEvent, ReactElement } from 'react';
import React, { Children, forwardRef, useCallback, useEffect, useState } from 'react';
import './cdt-carousel.scss';
import 'swiper/css';
import 'swiper/css/a11y';
import { A11y, Navigation, Virtual } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import type { SwiperProps } from 'swiper/swiper-react';

export interface Slide {
  imageURL: string;
  alt: string;
}

export interface CDTCarouselProps extends SwiperProps {
  children?: ReactElement | ReactElement[] | null;
  extra?: boolean;
  slideIndexToShow: number;
  addTabIndex?: string;
  callBack?: (pos: number) => void;
  synchronize?: (pos: number) => void;
}

const manageTabIndex = (wrapper: string) => {
  if (!wrapper) return null;
  const slides = document.querySelectorAll<HTMLDivElement>(`${wrapper} .swiper-slide`);
  slides.forEach((slide) => {
    slide.tabIndex = -1;
    if (slide.className.includes('swiper-slide-active')) {
      slide.tabIndex = 0;
      slide.focus();
    }
  });
};

const CdtCarousel: React.ForwardRefRenderFunction<HTMLDivElement, CDTCarouselProps> = (
  { children, extra, slideIndexToShow, addTabIndex, callBack, synchronize, ...rest },
  ref,
) => {
  const [swiperState, setSwiperState] = useState<SwiperClass | null>(null);
  const [spacerSlides, setSpacerSlides] = useState<string[]>([]);
  const extraIndex = Array.isArray(children) ? children.length : children ? 1 : 0;

  const handleSynchronizeClick = useCallback(() => {
    if (synchronize) {
      swiperState.once('slideChangeTransitionEnd', () => {
        synchronize(swiperState?.activeIndex);
      });
    }
  }, [swiperState, synchronize]);

  const handleSynchronizeKeyUp = useCallback(
    (e: KeyboardEvent<HTMLElement>) => {
      if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter') {
        if (synchronize) {
          swiperState.once('slideChangeTransitionEnd', () => {
            synchronize(swiperState?.activeIndex);
          });
        }
      }
    },
    [swiperState, synchronize],
  );

  const handleClick = (_: MouseEvent<HTMLElement>, pos: number) => {
    if (!callBack) return null;
    swiperState?.slideTo(pos);
    callBack(pos);
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLElement>, pos: number) => {
    switch (e.code) {
      case 'ArrowRight':
        swiperState?.slideTo(pos + 1);
        break;
      case 'ArrowLeft':
        swiperState?.slideTo(pos - 1);
        break;
      default:
        return null;
    }
  };

  // Allow for all items to be selectable in a carousel that items are selectable
  useEffect(() => {
    const slidesView = (rest.slidesPerView ?? 1) as number;
    if (Array.isArray(children) && slidesView > 1) {
      if ((children?.length + spacerSlides.length) % slidesView !== 0) {
        setSpacerSlides([...spacerSlides, '']);
      }
    }
  }, [children, rest.slidesPerView, spacerSlides]);

  /* if extra transition to that element
   * if slideIndexToShow transition to show
   * both values may be falsy but only one should be truthy at once
   * Goal is if extra is truthy show that item. Only if slideIndexToShow is
   *  truthy and extra is falsy show that slide
   */
  useEffect(() => {
    if (swiperState && swiperState.slides.length > 0) {
      if (extra) {
        setTimeout(() => {
          swiperState.slideTo(
            rest.virtual === false ? swiperState.slides.length - 1 : swiperState.virtual.slides.length - 1,
            300,
            false,
          );
        }, 100);
      }
      if (!extra && slideIndexToShow > -1) {
        setTimeout(() => {
          swiperState.slideTo(slideIndexToShow, 300, false);
        }, 100);
      }
    }
  }, [extra, rest.virtual, slideIndexToShow, swiperState, swiperState?.slides]);

  // Manage tab index
  useEffect(() => {
    // Check if swiperState is not null or undefined
    if (swiperState && swiperState.slides.length > 0) {
      if (addTabIndex) {
        swiperState.on('slideChangeTransitionEnd', () => manageTabIndex(addTabIndex));

        manageTabIndex(addTabIndex);
      }
    }
  }, [addTabIndex, swiperState]);

  useEffect(() => {
    if (swiperState) {
      const prev = swiperState.navigation.prevEl;
      const next = swiperState.navigation.nextEl;
      next.addEventListener('click', handleSynchronizeClick);
      prev.addEventListener('click', handleSynchronizeClick);
      next.addEventListener('keyup', handleSynchronizeKeyUp);
      prev.addEventListener('keyup', handleSynchronizeKeyUp);

      return () => {
        next.removeEventListener('click', handleSynchronizeClick);
        prev.removeEventListener('click', handleSynchronizeClick);
        next.removeEventListener('keyup', handleSynchronizeKeyUp);
        prev.removeEventListener('keyup', handleSynchronizeKeyUp);
      };
    }
  }, [handleSynchronizeClick, handleSynchronizeKeyUp, swiperState, synchronize]);

  return (
    <div className='cdt-carousel' ref={ref}>
      <Swiper virtual navigation modules={[Virtual, Navigation, A11y]} onSwiper={setSwiperState} {...rest}>
        {Array.isArray(children) &&
          Children.map(children, (child, index) => (
            <SwiperSlide
              virtualIndex={index}
              onClick={(e) => handleClick(e, index)}
              onKeyUp={(e) => handleKeyUp(e, index)}
            >
              {child}
            </SwiperSlide>
          ))}
        {!Array.isArray(children) && !!children && (
          <SwiperSlide virtualIndex={0} onClick={(e) => handleClick(e, 0)} onKeyUp={(e) => handleKeyUp(e, 0)}>
            {children}
          </SwiperSlide>
        )}
        {spacerSlides.map((_, index) => (
          <SwiperSlide
            key={index}
            virtualIndex={extraIndex}
            onClick={(e) => handleClick(e, extraIndex)}
            onKeyUp={(e) => handleKeyUp(e, extraIndex)}
          >
            <div>&nbsp;</div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default forwardRef<HTMLDivElement, CDTCarouselProps>(CdtCarousel);
