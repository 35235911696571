import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Accordion from '../cdt-accordion/cdt-accordion';
import CdtTabs from '../cdt-tabs/cdt-tabs';
import CdtWallContainer from '../cdt-wall-container/cdt-wall-container';
import Search from '../cdt-search/cdt-search';
import { GetDataResource } from '../../../../shared/hooks/use-get-data';
import { useLoaderData } from 'react-router-dom';
import type {
  AuthoredDataFilterT,
  AuthoredDataT,
  AuthoredFilterT,
  ColorDataT,
  ColorsT,
  ProductT,
} from '../../../../shared/types/types';
import { useFilterAuthoredData } from '../../../../shared/context/filter.context';

function Layout3(): ReactElement {
  const [[authoredDataResource], [colorDataResource], [productDataResource]] = useLoaderData() as [
    [GetDataResource<AuthoredDataT> | null, AbortController],
    [GetDataResource<ColorDataT> | null, AbortController],
    [GetDataResource<ProductT[]> | null, AbortController],
  ];
  const tabScrollRef = useRef<HTMLDivElement>(null);
  const { cloneAuthoredData, updateAuthoredData } = useFilterAuthoredData();
  const authoredData = authoredDataResource?.read();
  const colorData = colorDataResource?.read();
  const productData = productDataResource?.read();
  // const [authoredData, setAuthoredData] = useState<AuthoredDataT | null>(null);
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const [checkboxStates, setCheckboxStates] = useState<{ [key: string]: boolean }>({});
  const [searchKey, setSearchKey] = useState<string | null>(null);
  const [previousContext, setPreviousContext] = useState<string | null>(null);
  const [filteredColors, setFilteredColors] = useState<ColorsT[]>(colorData ? colorData.colors : []);

  const handleTabClick = (tabTag: string) => {
    setSelectedTab(tabTag);
  };
  const handleSearchResults = (results: ColorsT[]) => {
    setFilteredColors(results);
  };
  const handleSearchKey = (onSearch: string) => {
    setSearchKey(onSearch);
    // Update the authoredData object
    const updatedSearchInStep = {
      ...cloneAuthoredData?.searchInStep,
      colorFamilySearch: onSearch,
    };
    const updatedCloneAuthoredData = { ...cloneAuthoredData, searchInStep: onSearch };
    updateAuthoredData(updatedCloneAuthoredData as AuthoredDataT);
  };
  const handleCheckboxToggle = (filterItem: AuthoredFilterT) => {
    // Update the checkbox state
    setCheckboxStates((prev) => ({
      ...prev,
      [filterItem.filterTag]: !prev[filterItem.filterTag],
    }));

    // Update the authoredData object
    updateAuthoredData((cloneAuthoredData: AuthoredDataT | undefined) => {
      const updatedFilters = cloneAuthoredData?.filters.map((filter) => {
        if (filter.filterOptionLabel === filterItem.parentFilter) {
          const updatedFilterOptionList = filter.filterOptionList.map((option) => {
            if (option.filterTag === filterItem.filterTag) {
              return {
                ...option,
                checkBoxSelected: !option.checkBoxSelected,
              };
            }
            return option;
          });
          return {
            ...filter,
            filterOptionList: updatedFilterOptionList,
          };
        }
        return filter;
      });

      return { ...cloneAuthoredData, filters: updatedFilters } as AuthoredDataT; // eslint-disable-line
    });
  };

  useEffect(() => {
    if (cloneAuthoredData?.searchInStep?.colorFamilySearch) {
      setPreviousContext(cloneAuthoredData?.searchInStep?.colorFamilySearch ?? null);
    }
  }, []);

  useEffect(() => {
    if (colorData) {
      let updatedResults = colorData.colors;
      if (selectedTab) {
        updatedResults = updatedResults.filter((color) => {
          return selectedTab.split(":").reduce((acc, val) => {
            val = val.replace(/_/g, ' ');
            return (
                acc ||
                (color.secondaryColorFamily?.toLowerCase().includes(val.toLowerCase()) ?? false) ||
                (color.colorFamily?.toLowerCase().includes(val.toLowerCase()) ?? false) ||
                (color.collection?.some((collectionItem: string) =>
                    collectionItem.toLowerCase().includes(val.toLowerCase())
                ) ?? false)
            );
          }, false);
        });
      }
      const anyCheckboxChecked = Object.values(checkboxStates).some((isChecked) => isChecked);

      if (anyCheckboxChecked) {
        const filterColors = (colors: ColorsT[], filters: AuthoredDataFilterT[]) => {
          return colors.filter((color) => {
            let satisfiesFilters = true; // Initialize to true

            // Iterate through each filter option
            filters.forEach((filterOption) => {
              let satisfiesFilterOption = false;

              // Iterate through each filter in the filter option
              filterOption.filterOptionList.forEach((filter) => {
                if (filter.checkBoxSelected) {
                  // Apply OR condition within a filter option
                  if (color.colorShade === filter.filterTag || color.transparency === filter.filterTag) {
                    satisfiesFilterOption = true;
                  }
                }
              });

              // If no filter options are selected, consider the filter option as satisfied
              if (filterOption.filterOptionList.every((filter) => !filter.checkBoxSelected)) {
                satisfiesFilterOption = true;
              }

              // Apply AND condition between filter options
              satisfiesFilters = satisfiesFilters && satisfiesFilterOption;
            });

            return satisfiesFilters;
          });
        };
        updatedResults = filterColors(updatedResults, cloneAuthoredData?.filters ?? []);
      }

      // Filter based on search key
      if (searchKey) {
        updatedResults = updatedResults.filter(
          (color) =>
            color.colorName.toLowerCase().includes(searchKey.toLowerCase()) ||
            color.mdmId.toLowerCase().includes(searchKey.toLowerCase()) ||
            color.colorNumber?.toLowerCase().includes(searchKey.toLowerCase()) ||
            color.colorFamily?.toLowerCase().includes(searchKey.toLowerCase()),
        );
        if (tabScrollRef.current) {
          tabScrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          // Scroll the element into view, aligning its top with the top of the viewport
          setTimeout(() => {
            // Manually trigger the resize event after the scrollIntoView assuming scroll completes in 500ms
            const resizeEvent = new Event('resize');
            window.dispatchEvent(resizeEvent);
          }, 500);
        }
      }

      setFilteredColors(updatedResults);
    }
  }, [checkboxStates, cloneAuthoredData?.filters, colorData, searchKey, selectedTab]);

  if (!authoredData) return <></>;
  if (!colorData) return <></>;
  if (!productData) return <></>;

  return (
    <div className='cmp-step-three-container'>
      <div>
        <h3 className='explore-colors-title'>{authoredData.exploreColorsTitle}</h3>
      </div>
      <div className='cmp-color-family-search-container'>
        <Search
          label={authoredData.searchLabel}
          onSearch={handleSearchKey}
          colorData={filteredColors}
          whileSearching={handleSearchResults}
          context={previousContext}
          searchButtonAriaLabel={authoredData.searchButtonAriaLabel}
          clearSearchButtonAriaLabel={authoredData.clearSearchButtonAriaLabel}
        />
      </div>
      <div className='cmp-filters-container'>
        <div className='cmp-color-opacity'>
          <Accordion data={authoredData.filters} authoredData={authoredData} onCheckboxToggle={handleCheckboxToggle} />
        </div>
        <div ref={tabScrollRef} className='cdt-tabs-main-container'>
          <CdtTabs tabs={authoredData.colorCategories} selectedTab={selectedTab} onTabClick={handleTabClick} />
        </div>
      </div>
      <div className='cdt-wall'>
        <CdtWallContainer
          colorData={filteredColors}
          authoredData={authoredData}
          productData={productData}
          searchKey={searchKey}
          settingsData={{ channelId: colorData.channelId, localeId: colorData.localeId }}
        />
      </div>
    </div>
  );
}

export default Layout3;
