import React, { KeyboardEvent, useContext, useEffect } from 'react';
import './cdt-clear-favorite-popup.scss';
import { FavoritesDispatchContext } from '../../../../shared/context/favorite.context';
import CdtButton from '../cdt-button/cdt-button';
import type { AuthoredDataT } from '../../../../shared/types/types';

export interface CdtClearFavoritePopupProps {
  authoredData: AuthoredDataT;
  popupState: [boolean, React.Dispatch<boolean>];
}

const CdtClearFavoritePopup: React.FC<CdtClearFavoritePopupProps> = ({ authoredData, popupState }) => {
  const [popupVisible, setPopupVisible] = popupState;
  const dispatch = useContext(FavoritesDispatchContext);

  const handleCancel = () => {
    setPopupVisible(false);
  };
  const handleClearAll = () => {
    if (!dispatch) return;
    dispatch({
      type: 'clear_favorite',
    });

    setPopupVisible(false);
    // Where to move focus
    const step1Button = document.querySelector<HTMLAnchorElement>('.cdt-button-container__button');
    const colorDetailsCloseButton = document.querySelector<HTMLAnchorElement>('.cdt-color-details-title-button');
    if (step1Button) {
      step1Button.focus();
    } else if (colorDetailsCloseButton) {
      colorDetailsCloseButton.focus();
    }
  };

  const handleOnKeyDown = (e: KeyboardEvent) => {
    e.preventDefault();
    const element = e.target as HTMLButtonElement;
    switch (e.code) {
      case 'Tab':
        if (element.className.includes('cancel-button')) {
          document.querySelector<HTMLButtonElement>('.cdt-clear-favorite-popup .clear-all-button')?.focus();
        }
        if (element.className.includes('clear-all-button')) {
          document.querySelector<HTMLButtonElement>('.cdt-clear-favorite-popup .cancel-button')?.focus();
        }
        break;
      case 'Enter':
        if (element.className.includes('cancel-button')) {
          handleCancel();
        }
        if (element.className.includes('clear-all-button')) {
          handleClearAll();
        }
        break;
    }
  };

  useEffect(() => {
    if (popupVisible) {
      setTimeout(
        () => document.querySelector<HTMLButtonElement>('.cdt-clear-favorite-popup .cancel-button')?.focus(),
        100,
      );
    } else {
      setTimeout(
        () => document.querySelector<HTMLButtonElement>('.cdt-color-bar-fav-container .clear-all-button')?.focus(),
        100,
      );
    }
  }, [popupVisible]);

  return (
    <div className={`cdt-clear-favorite-popup${popupVisible ? ' show' : ''}`}>
      <div className='cdt-clear-favorite-popup-content'>
        <h4>{authoredData.clearFavoritePopupHeading}</h4>
        <p>{authoredData.clearFavoritePopupBody}</p>
      </div>
      <div className='cdt-clear-favorite-popup-ctas'>
        <CdtButton
          style='button'
          ariaLabel={authoredData.clearFavoritePopupCancelAriaLabel}
          label={authoredData.clearFavoritePopupCancelCTA}
          className='cancel-button cbg-cdt-button'
          onClick={handleCancel}
          onKeyDown={handleOnKeyDown}
        />
        <CdtButton
          style='button'
          ariaLabel={authoredData.clearFavoritePopupClearAllAriaLabel}
          label={authoredData.clearFavoritePopupClearAllCTA}
          className='clear-all-button cbg-cdt-button'
          onClick={handleClearAll}
          onKeyDown={handleOnKeyDown}
        />
      </div>
    </div>
  );
};
export default CdtClearFavoritePopup;
