import React from 'react'
import './cdt-image-text.scss'
import CdtButton from '../cdt-button/cdt-button'

export interface CdtImageTextProps {
  fileReference: string
  fileReferenceMobile: string
  altText: string
  description: string
  label: string
  ariaLabel: string
  inside?: boolean
  href: string
}

const CdtImageText: React.FC<CdtImageTextProps> = ({
  fileReference,
  fileReferenceMobile,
  altText,
  description,
  label,
  ariaLabel,
  inside = false,
  href,
}) => {
  return (
    <div className='cdt-image-text'>
      <div className='cdt-image-text__image'>
        <picture>
          <source media='(min-width: 800px)' srcSet={fileReference} />
          <img src={fileReferenceMobile} alt={altText} />
        </picture>
      </div>
      <div className='cdt-image-text__content'>
        <div className='cdt-image-text__description'>{description}</div>
        <div className='cdt-image-text__cta'>
          <CdtButton
            style='link'
            label={label}
            ariaLabel={ariaLabel}
            inside={inside}
            href={href}
            icon='mwarrow_desktop'
            className='cbg-cdt-button'
          />
        </div>
      </div>
    </div>
  )
}
export default CdtImageText
