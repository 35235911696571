import React, { FormEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import type {AuthoredDataT, ColorsT} from '../../../../shared/types/types';
import Responsive from '../cdt-responsive';
const MIN_WIDTH = 799;
const MAX_WIDTH = 3400;

export interface SearchProps {
  onSearch?: (searchTerm: string) => void;
  label: string;
  colorData?: ColorsT[];
  whileSearching?: (results: ColorsT[]) => void;
  context?: string | null;
  searchButtonAriaLabel?: string;
  clearSearchButtonAriaLabel?: string;
}

const Search: React.FC<SearchProps> = ({ label, onSearch, colorData,searchButtonAriaLabel, clearSearchButtonAriaLabel ,whileSearching, context }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showClearIcon, setShowClearIcon] = useState(false);
  const previousContextRef = useRef<string | null>(null);

  useEffect(() => {
    // Trigger search with default term if context is provided
    if (context && onSearch && context !== previousContextRef.current) {
      setSearchTerm(context); // Set search term to context value
      setShowClearIcon(true);
      previousContextRef.current = context; // Update previous context
      onSearch?.(context);
    }
  }, [context, onSearch]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setShowClearIcon(false);
  };

  const handleSubmit = (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (onSearch) {
      onSearch(searchTerm);
    }

    if (whileSearching) {
      const results: ColorsT[] =
        colorData?.filter(
          (color) =>
            color.colorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            color.mdmId.toLowerCase().includes(searchTerm.toLowerCase()) ||
            color.colorNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            color.colorFamily?.toLowerCase().includes(searchTerm.toLowerCase()),
        ) ?? [];
      whileSearching(results);
    }

    setShowClearIcon(true);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (onSearch) {
        onSearch(searchTerm);
      }
      setShowClearIcon(true);
      handleSubmit();
    }
  };

  const handleClear = () => {
    setSearchTerm('');
    if (onSearch) onSearch('');
    setShowClearIcon(false);
    if (whileSearching) {
      whileSearching(colorData ?? []);
    }
  };

  return (
    <form className='search-form-container' onSubmit={handleSubmit}>
      <Responsive
        minWidth={MIN_WIDTH}
        maxWidth={MAX_WIDTH}
        desktopContent={
          <>
            <input
              type='text'
              id='searchInput'
              placeholder={label}
              value={searchTerm}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className='search-label'
            />
            {showClearIcon && <button type='button' aria-label={clearSearchButtonAriaLabel} className='cmp-search-clear-icon' onClick={handleClear} />}
            {!showClearIcon && <button type='submit' aria-label={searchButtonAriaLabel} className='cmp-search-icon' />}
          </>
        }
        mobileContent={
          <>
            <button type='submit' aria-label={searchButtonAriaLabel} className='cmp-search-icon mobile' />
            <input
              type='text'
              id='searchInput'
              placeholder={label}
              value={searchTerm}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className='search-label'
            />
            {showClearIcon && <button type='button' aria-label={clearSearchButtonAriaLabel} className='cmp-search-clear-icon' onClick={handleClear} />}
          </>
        }
      />
    </form>
  );
};

export default Search;
