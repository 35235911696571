import React from 'react';
import './cdp-article.scss';
import CdtButton from '../cdt-button/cdt-button';
import type { AuthoredDataArticleItemsLinkT } from '../../../../shared/types/types';

export interface CdtCDPArticleProps {
  article: AuthoredDataArticleItemsLinkT;
}

const CDPArticle: React.FC<CdtCDPArticleProps> = ({ article }) => {
  return (
    <div className='cdp-article'>
      <div className='cdp-article-image'>
        <picture>
          <source media='(min-width: 800px)' srcSet={article.fileReference} />
          <img src={article.fileReferenceMobile} alt={article.articleAltText} />
        </picture>
      </div>
      <div className='cdp-article-content'>
        <div className='cdp-article-description'>{article.articleItemDescription}</div>
        <div className='cdp-article-cta'>
          <CdtButton
            style='link'
            label={article.articleButtonLinkCTA}
            ariaLabel={article.articleButtonLinkAria}
            href={`${article.articleButtonLink ? article.articleButtonLink : '#'}`}
            icon='mwarrow_desktop'
            inside={article.articleButtonLinkExternal === 'internal'}
            className='cbg-cdt-button'
          />
        </div>
      </div>
    </div>
  );
};
export default CDPArticle;
