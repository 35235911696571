import React, { createContext, useEffect, useReducer } from 'react';
import useFavorite, { addFavorite, removeFavorite } from '../hooks/use-favorite';
import type { CartAPIFavoriteT, ColorDataT, ColorsT } from '../types/types';

export const FavoritesContext = createContext<CartAPIFavoriteT[]>([]);
export const FavoritesDispatchContext = createContext<React.Dispatch<Action> | null>(null);

type Action =
  | { type: 'add_favorite'; color: ColorsT }
  | { type: 'remove_favorite'; favID: string }
  | { type: 'clear_favorite' }
  | { type: 'local_favorite_update'; localFavorites: CartAPIFavoriteT[] };

interface FavoriteProviderProps {
  children: React.ReactNode;
  colorData: ColorDataT;
}

export const FavoriteProvider: React.FC<FavoriteProviderProps> = ({ children, colorData }) => {
  const localFavorites = useFavorite();
  // I cannot figure out the correct type here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [favorites, dispatch] = useReducer<React.Reducer<any, Action>>(
    favoritesReducer,
    getFavoriteThumbnail(localFavorites ?? []),
  );

  useEffect(() => {
    dispatch({
      type: 'local_favorite_update',
      localFavorites: localFavorites ?? [],
    });
  }, [localFavorites]);

  function favoritesReducer(favorites: CartAPIFavoriteT[], action: Action) {
    switch (action.type) {
      case 'add_favorite': {
        addFavorite(action.color);
        return getFavoriteThumbnail([
          ...(favorites ?? []),
          {
            // This allows for optimistic updating and will be replaced after the round trip to the server
            ...action.color,
            cartId: 0,
            colorId: 0,
            localeId: 0,
            channelId: 0,
            channelName: '',
            favorite: true,
            colors: [],
            mdmColorId: action.color.mdmId,
            swatchUrl: '',
          },
        ]);
      }
      case 'remove_favorite': {
        removeFavorite(action.favID);
        return (favorites ?? []).filter((fav) => !(fav.mdmId === action.favID || fav.mdmColorId === action.favID));
      }
      case 'clear_favorite': {
        (favorites ?? []).forEach((fav) => {
          fav.mdmColorId ? removeFavorite(fav.mdmColorId) : removeFavorite(fav.mdmId);
        });
        return [];
      }
      case 'local_favorite_update': {
        return getFavoriteThumbnail(action.localFavorites ?? []);
      }
      default: {
        // @ts-ignore
        throw Error('Unknown action: ' + action.type);
      }
    }
  }

  // take an the favorites array and append thumbnail url for each favorite
  function getFavoriteThumbnail(favoritesParam: CartAPIFavoriteT[]): CartAPIFavoriteT[] {
    if (!colorData) {
      return favoritesParam;
    }

    return favoritesParam.map((fav) => {
      const favColorData = colorData.colors.find(
        (color) => fav.mdmId === color.mdmId || fav.mdmColorId === color.mdmId,
      );
      let favSwatchURL = favColorData?.associatedAssets.find((aa) => aa.type === 'ATT_Carousel_Image_02')?.url;
      if (typeof favSwatchURL === 'undefined') {
        favSwatchURL = favColorData?.associatedAssets.find((aa) => aa.type === 'ATT_Carousel_Image_01')?.url;
      }
      fav.swatchUrl = typeof favSwatchURL !== 'undefined' ? favSwatchURL : '';
      return fav;
    });
  }

  return (
    <FavoritesContext.Provider value={favorites}>
      <FavoritesDispatchContext.Provider value={dispatch}>{children}</FavoritesDispatchContext.Provider>
    </FavoritesContext.Provider>
  );
};
