import React, { KeyboardEvent } from 'react';
import './cdt-button.scss';
import { Link, useInRouterContext } from 'react-router-dom';

export interface CdtButtonProps {
  style: 'button' | 'link';
  label: string;
  className: string;
  ariaLabel?: string;
  onClick?: () => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  href?: string;
  search?: Record<string, string>;
  icon?: string;
  inside?: boolean;
  id?: string;
}

const CdtButton: React.FC<CdtButtonProps> = ({
  style,
  label,
  className,
  ariaLabel,
  onClick,
  onKeyDown,
  href,
  search,
  icon,
  inside = true,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (onKeyDown) {
      onKeyDown(e);
    }
  };
  const inRouterContext = useInRouterContext();

  if (style === 'button') {
    return (
      <button
        type='button'
        className={className}
        onClick={handleClick}
        onKeyDown={handleOnKeyDown}
        aria-label={ariaLabel}
      >
        {label}
      </button>
    );
  } else if (style === 'link' && inRouterContext && inside) {
    let modHref = href ?? '';
    const iterable: Record<string, string> = { ...search };
    for (const key in iterable) {
      if (!modHref.includes('?')) {
        modHref = `${modHref}?${key}=${iterable[key]}`;
      } else {
        modHref = `${modHref}&${key}=${iterable[key]}`;
      }
    }
    return (
      <Link className={className} to={modHref} aria-label={ariaLabel}>
        {label && <span className={`${className}__text`}>{label}</span>}
        {icon && (
          <span className={`${className}__icon-wrapper`}>
            <svg className={`${className}__icon`}>
              <use href={`/etc.clientlibs/cbg-platform/clientlibs/clientlib-site/resources/fonts/sprite.svg#${icon}`} />
            </svg>
          </span>
        )}
      </Link>
    );
  } else if (style === 'link') {
    return (
      <a className={className} href={href ?? ''} aria-label={ariaLabel}>
        {label && <span className={`${className}__text`}>{label}</span>}
        {icon && (
          <span className={`${className}__icon-wrapper`}>
            <svg className={`${className}__icon`}>
              <use href={`/etc.clientlibs/cbg-platform/clientlibs/clientlib-site/resources/fonts/sprite.svg#${icon}`} />
            </svg>
          </span>
        )}
      </a>
    );
  }
  return <></>;
};

export default CdtButton;
