import React, { forwardRef, KeyboardEvent, MouseEvent, ReactElement, useContext, useEffect, useState } from 'react';
import './cdt-color-details.scss';
import CdtButton from '../cdt-button/cdt-button';
import CdtCDPArticles from '../cdt-cdp-articles/cdt-cdp-articles';
import CdtColorCard from '../cdt-color-card/cdt-color-card';
import CdtDidYouKnow from '../cdt-did-you-know/cdt-did-you-know';
import CdtFavorite from '../cdt-favorite/cdt-favorite';
import useFavorite, { handleFavoriteCallback, isFavorite } from '../../../../shared/hooks/use-favorite';
import { SelectedColorContext, SelectedColorDispatchContext } from '../../../../shared/context/selectedColor.context';
import { useProjectContext } from '../../../../shared/context/project.context';
import { useNavigate } from 'react-router-dom';
import type { AuthoredDataT, ColorsT, ProductT } from '../../../../shared/types/types';

export interface CdtColorDetailsProps {
  authoredData: AuthoredDataT;
  colors: ColorsT[];
  productData: ProductT[];
  settingsData: {
    channelId: number;
    localeId: number;
  };
}

const CdtColorDetails: React.ForwardRefRenderFunction<HTMLDivElement, CdtColorDetailsProps> = (
  { authoredData, colors, productData, settingsData },
  ref,
): ReactElement => {
  const [color, setColor] = useState<ColorsT | null>(null);
  const [relatedColor, setRelatedColor] = useState<ColorsT[] | null>(null);
  const [product, setProduct] = useState<ProductT | null>(null);
  const [relatedColorProducts, setRelatedColorProducts] = useState<Record<string, ProductT> | null>(null);
  const [project] = useProjectContext() ?? [];
  const [projectName, setProjectName] = useState('');
  const [isFavoriteValue, setIsFavoriteValue] = useState(false);
  const favorites = useFavorite();
  const selectedColor = useContext(SelectedColorContext);
  const disptchSelectedColor = useContext(SelectedColorDispatchContext);
  const navigate = useNavigate();

  const findColorVariations = (color: ColorsT | null, colors: ColorsT[]): ColorsT[] | null => {
    const matchingColors: ColorsT[] = [];

    if (color?.colorVariations) {
      color.colorVariations.forEach((cv) => {
        if (cv.mdmId) {
          const matchingColor = colors.find((c) => c.mdmId === cv.mdmId);
          if (matchingColor) {
            matchingColors.push(matchingColor);
            const relatedProduct = productData.find(
              (product) => product.productId === matchingColor.associatedProducts[0].productNumber,
            );
            if (relatedProduct) {
              setRelatedColorProducts((previous) => ({
                ...(previous ?? {}),
                [matchingColor.mdmId]: relatedProduct ?? {},
              }));
            }
          }
        }
      });
    }
    return matchingColors.length > 0 ? matchingColors : null;
  };

  const scrollToCarouselTop = () => {
    const carousel = document.querySelector('.cmp-color-demonstration-tool .cdt-carousel');
    if (carousel) {
      carousel.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  const handleColorClick = (_: MouseEvent<HTMLElement>, to: string) => {
    navigate(to);
    scrollToCarouselTop();
  };

  const handleColorOnKeyUp = (e: KeyboardEvent<HTMLElement>, to: string) => {
    switch (e.code) {
      case 'Enter':
        navigate(to);
        scrollToCarouselTop();
        break;
      default:
        return null;
    }
  };

  useEffect(() => {
    setProjectName(
      project?.itemTitle?.toLowerCase() ?? authoredData?.itemSelector[0].itemTitle?.toLowerCase() ?? 'dresser',
    );
  }, [authoredData?.itemSelector, project?.itemTitle]);

  useEffect(() => {
    if (selectedColor && colors) {
      const needle = colors.find((color) => color.mdmId === selectedColor.mdmId);
      if (needle) {
        setColor(needle);
        const matchingColor = findColorVariations(needle, colors);
        setRelatedColor(matchingColor);
        if (productData) {
          const product = productData.find(
            (product) => product.productId === needle.associatedProducts[0].productNumber,
          );
          if (product) setProduct(product);
        }
        if (favorites) {
          setIsFavoriteValue(isFavorite(needle, favorites));
        }
      }
    }
  }, [colors, favorites, productData, selectedColor]);

  useEffect(() => {
    if (!selectedColor && colors && disptchSelectedColor) {
      disptchSelectedColor({ type: 'ADD_SELECTED_COLOR', color: colors[0] });
    }
  }, [colors, disptchSelectedColor, selectedColor]);

  if (!color) return <></>;

  return (
    <div className='cdt-color-details' ref={ref}>
      <div className='cdt-color-details__title cdt-color-details-title'>
        <span className='cdt-color-details-title__text'>{authoredData?.colorDetailTitle}</span>
        <CdtButton
          style='link'
          label=''
          ariaLabel={authoredData.stepTitle3Aria}
          href={`/${authoredData.stepTitle3?.toLowerCase().replaceAll(' ', '-')}/${projectName}`}
          icon='mwx'
          className='cdt-color-details-title-button'
        />
      </div>
      <div className='cdt-color-details__swatch-container'>
        <div className='cdt-color-details__swatch'>
          <CdtFavorite
            favorite={isFavoriteValue}
            onClick={() => handleFavoriteCallback(color, isFavoriteValue, settingsData)}
            hideFavorite={authoredData.hideFavorites}
          />
          <img
            src={color.associatedAssets.find((aa) => aa.type === 'ATT_Carousel_Image_01')?.url ?? ''}
            alt={color.colorName}
          />
        </div>
      </div>
      <div className='cdt-color-details__color-name'>
        {color.colorName} {color.colorNumber}
      </div>
      <div className='cdt-color-details__product-name'>{product?.productName}</div>
      <div className='cdt-color-details__transparency'>{color.transparency}</div>
      <div className='cdt-color-details__where-to-buy'>
        <CdtButton
          style='link'
          label={authoredData.wtbButtonCTA}
          ariaLabel={authoredData.wtbButtonAria}
          href={authoredData.wtbButtonPath}
          icon='mwright-chevron'
          className='cdt-color-details-where-to-buy-button'
          inside={false}
        />
      </div>
      <div className='cdt-color-details__disclamer'>{authoredData.disclaimerText}</div>
      {relatedColor && relatedColor.length > 0 && (
        <div className='cdt-related-colors-container'>
          <div className='cdt-related-colors-name-container'>
            <p className='cdt-related-colors-name'>
              {authoredData.colorVariationTitle?.replace('%colorName%', color.colorName)}
            </p>
          </div>
          <div className='cdt-color-details__related_colors'>
            {relatedColor.map((color) => {
              const isSelected = color.mdmId === selectedColor?.mdmId;
              const to = `/${authoredData?.colorDetailTitle?.toLowerCase().replaceAll(' ', '-')}/${projectName}/${
                color.mdmId
              }`;
              return (
                <div
                  className={`cdt-each-card${isSelected ? ' cdt-each-card--selected' : ''}`}
                  key={color.id}
                  onClick={(e) => handleColorClick(e, to)}
                  onKeyUp={(e) => handleColorOnKeyUp(e, to)}
                  tabIndex={0}
                >
                  <CdtColorCard
                    color={color}
                    productName={relatedColorProducts?.[color.mdmId].productName ?? ''}
                    authoredData={authoredData}
                    favorite={isFavorite(color, favorites)}
                    favoriteCallback={handleFavoriteCallback}
                    settingsData={settingsData}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <CdtDidYouKnow authoredData={authoredData} product={product} color={color} />
      <CdtCDPArticles authoredData={authoredData} />
    </div>
  );
};

export default forwardRef<HTMLDivElement, CdtColorDetailsProps>(CdtColorDetails);
