import React, { forwardRef, ReactElement, Suspense } from 'react';
import './color-demonstration-tool-filter.scss';
import Spinner from '../../../../shared/componets/spinner';
import { NavLink, Outlet, useMatches } from 'react-router-dom';
import type { AuthoredDataT } from '../../../../shared/types/types';
import { useProjectContext } from '../../../../shared/context/project.context';

interface ColorDemonStrationToolFilterT {
  authoredData: AuthoredDataT;
}

const ColorDemonStrationToolFilter: React.ForwardRefRenderFunction<HTMLDivElement, ColorDemonStrationToolFilterT> = (
  { authoredData },
  ref,
): ReactElement => {
  const { stepTitle1, stepTitle2, stepTitle3, stepTitle1Aria, stepTitle2Aria, stepTitle3Aria } = authoredData;
  const stepTitlesArr = [stepTitle1?.toUpperCase(), stepTitle2?.toUpperCase(), stepTitle3?.toUpperCase()];
  const stepTitlesAriaArr = [stepTitle1Aria, stepTitle2Aria, stepTitle3Aria];
  // TODO: verify this is pulling the default correctly
  const [project] = useProjectContext() ?? [];
  const projectName =
    project?.itemTitle?.toLowerCase() ?? authoredData.itemSelector[0].itemTitle?.toLowerCase() ?? 'dresser';
  const pathMatches = useMatches();

  return (
    <div className='cmp-color-demonstration-tool-filter' ref={ref}>
      <div className='cdt-button-container'>
        {stepTitlesArr.map((title, index) => {
          const tabValue = title.toLowerCase().replaceAll(' ', '-');
          const fromPathMatches = pathMatches[pathMatches.length - 1].pathname.split('/')[1];
          return (
            <>
              {title && (
                <NavLink
                  to={`/${tabValue}/${projectName}`}
                  className={({ isActive, isPending }) =>
                    isActive || fromPathMatches === tabValue
                      ? 'cdt-button-container__button cdt-button-container__button--active'
                      : isPending
                      ? 'cdt-button-container__button cdt-button-container__button--pending'
                      : 'cdt-button-container__button'
                  }
                  key={`${title}-${index}`}
                  aria-label={stepTitlesAriaArr[index]}
                >
                  {title}
                </NavLink>
              )}
              {stepTitlesArr[++index] && <span className='cdt-button-container__divider'>|</span>}
            </>
          );
        })}
      </div>
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    </div>
  );
};

export default forwardRef<HTMLDivElement, ColorDemonStrationToolFilterT>(ColorDemonStrationToolFilter);
