import React, { KeyboardEvent, ReactElement, useEffect, useRef, useState } from 'react';
import type { AuthoredDataT } from '../../../../shared/types/types';
import { useProjectContext } from '../../../../shared/context/project.context';

import './cdt-thumbnails.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Exporting the interface for external use
export interface ProjectSelectorProps {
  itemSelector: AuthoredDataT['itemSelector'];
}

interface ItemProps {
  active: boolean;
  item: AuthoredDataT['itemSelector'][number];
  onClick: () => void;
  onKeyUp: (e: KeyboardEvent<HTMLElement>) => void;
}

const Item = React.memo(({ active, item, onClick, onKeyUp }: ItemProps) => {
  return (
    <div className={`cmp-each-item${active ? ' cmp-each-item--active' : ''}`} onClick={onClick} onKeyUp={onKeyUp}>
      <img
        src={item.fileReference}
        alt={item.altText ?? 'item image'}
        className='cmp-item-image'
        tabIndex={active ? 0 : -1}
      />
      <p className='cmp-item-title'>{item.itemTitle}</p>
    </div>
  );
});

Item.displayName = 'Item';

const ProjectSelector: React.FC<ProjectSelectorProps> = ({ itemSelector }): ReactElement => {
  // get state and setState function from global project context
  const [_, setProject] = useProjectContext() ?? [];
  const itemsRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (itemSelector && itemSelector.length > 0) {
      // set initial selected project state
      if (activeIndex !== null && setProject) {
        const step = location.pathname.split('/')[1];
        const projectName = itemSelector[activeIndex].itemTitle?.toLowerCase();
        if (projectName && projectName !== params.projectName) {
          navigate(`/${step}/${projectName}`);
        }
        // set initial active index
      } else if (activeIndex === null && params.projectName) {
        setActiveIndex(itemSelector.findIndex((item) => item.itemTitle?.toLowerCase() === params.projectName));
      }
    }
  }, [activeIndex, itemSelector, location, navigate, params.projectName, setProject]);

  if (!itemSelector || itemSelector.length === 0) {
    return <div>No items available.</div>;
  }

  const handleItemClick = (index: number) => {
    setActiveIndex(index);
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLElement>, pos: number) => {
    e.stopPropagation();
    e.preventDefault();
    const itemEach = itemsRef.current?.querySelectorAll<HTMLDivElement>('.cmp-each-item img');

    switch (e.code) {
      case 'ArrowRight': {
        const newActivePos = Math.min(pos + 1, (itemEach?.length ?? 1) - 1);
        const newActive = itemEach?.[newActivePos];
        handleItemClick(newActivePos);
        newActive?.focus();
        newActive?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
        break;
      }
      case 'ArrowLeft': {
        const newActivePos = Math.max(pos - 1, 0);
        const newActive = itemEach?.[newActivePos];
        handleItemClick(newActivePos);
        newActive?.focus();
        newActive?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
        break;
      }
      default:
        return null;
    }
  };

  return (
    <div className='cmp-items' ref={itemsRef}>
      {itemSelector.map((item, index) => {
        if (item.fileReference && item.itemTitle) {
          return (
            <Item
              key={index}
              active={index === activeIndex}
              item={item}
              onClick={() => handleItemClick(index)}
              onKeyUp={(e) => handleKeyUp(e, index)}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

ProjectSelector.displayName = 'ProjectSelector';

export default ProjectSelector;
