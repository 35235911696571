import React from 'react';
import './cdt-expand-button.scss';

export interface CdtExpandButtonProps {
  onClick: () => void;
  label: string;
  colorCounter: number;
}

const CdtExpandButton: React.FC<CdtExpandButtonProps> = ({ onClick, label, colorCounter }) => {
  return (
    <button onClick={onClick} className='expand-button'>
      {label} ({colorCounter})
    </button>
  );
};

export default CdtExpandButton;
