import React, { useEffect, useState } from 'react';
import './cdt-accordion.scss';
import Checkbox from '../cdt-checkbox/cdt-checkbox';
import Responsive from '../cdt-responsive';
import CdtButton from '../cdt-button/cdt-button';
import type { AuthoredDataFilterT, AuthoredDataT, AuthoredFilterT } from '../../../../shared/types/types';
import { createPortal } from 'react-dom';

const MOBILE_THRESHOLD = 799;

export interface AccordionProps {
  data: AuthoredDataFilterT[];
  authoredData: AuthoredDataT;
  onCheckboxToggle: (filterItem: AuthoredFilterT) => void;
}

const Accordion: React.FC<AccordionProps> = ({ data, authoredData, onCheckboxToggle }) => {
  const [accordionState, setAccordionState] = useState<{
    expandedIndices: number[];
    checkboxStates: { [key: string]: boolean };
  }>({
    expandedIndices: [],
    checkboxStates: {},
  });

  const [_, setIsMobileView] = useState<boolean>(window.innerWidth <= MOBILE_THRESHOLD);
  const [showAccordionOverlay, setShowAccordionOverlay] = useState<boolean>(false);

  const toggleAccordion = (index: number) => {
    setAccordionState((prev) => ({
      ...prev,
      expandedIndices: prev.expandedIndices.includes(index)
        ? prev.expandedIndices.filter((i) => i !== index)
        : [...prev.expandedIndices, index],
    }));
  };

  const toggleCheckbox = (filterItem: AuthoredFilterT) => {
    setAccordionState((prev) => ({
      ...prev,
      checkboxStates: { ...prev.checkboxStates, [filterItem.filterTag]: !prev.checkboxStates[filterItem.filterTag] },
    }));

    onCheckboxToggle(filterItem);
  };

  const handleToggleMobileAccordion = () => {
    setShowAccordionOverlay(!showAccordionOverlay);
    const body = document.body;
    body.classList.toggle('body-overflow-hidden', !showAccordionOverlay);
  };

  const handleMobileClearAll = () => {
    data.forEach((filter) => {
      filter.filterOptionList.forEach((filterItem) => {
        if (
          typeof accordionState.checkboxStates[filterItem.filterTag] !== 'undefined' &&
          accordionState.checkboxStates[filterItem.filterTag]
        ) {
          toggleCheckbox(filterItem);
        }
      });
    });
  };

  const handleAccordionKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      toggleAccordion(index);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= MOBILE_THRESHOLD);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Responsive
      minWidth={MOBILE_THRESHOLD + 1}
      maxWidth={3400}
      desktopContent={
        <div className='accordion-container'>
          {data.map((item, index) => (
            <div
              className={`cdt-accordion ${accordionState.expandedIndices.includes(index) ? 'expanded' : ''}`}
              key={index}
              tabIndex={0}
              onKeyDown={(event) => handleAccordionKeyDown(event, index)}
              aria-expanded={accordionState.expandedIndices.includes(index)}
            >
              <div className='cdt-accordion-header' onClick={() => toggleAccordion(index)}>
                <span className='cdt-accordion-title'>{item.filterOptionLabel}</span>
                <span
                  className={`cdt-icon ${
                    accordionState.expandedIndices.includes(index) ? 'cdt-collapse-icon' : 'cdt-expand-icon'
                  }`}
                />
              </div>
              {accordionState.expandedIndices.includes(index) && (
                <div className='cdt-accordion-content'>
                  <Checkbox
                    filterOptionList={item.filterOptionList}
                    checkboxStates={accordionState.checkboxStates}
                    onCheckboxToggle={(filterItem: AuthoredFilterT) => toggleCheckbox(filterItem)} // Pass entire filterItem object
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      }
      mobileContent={
        <>
          <button
            aria-label={authoredData?.filterButtonMobileAriaLabel}
            className='mobile-accordion-toggle-button'
            onClick={handleToggleMobileAccordion}
          >
            {authoredData?.filterButtonMobileCTA}
          </button>
          {createPortal(
            <div className={`mobile-accordion-overlay ${showAccordionOverlay ? 'show-overlay' : ''}`}>
              <div className='overlay-content'>
                <div className='filters-header'>
                  <div className='header-icon' />
                  <div className='header-text'>{authoredData?.filterButtonMobileCTA}</div>
                  <div className='header-close-icon' onClick={handleToggleMobileAccordion} />
                </div>
                <div className='cdt-accordion mobile-accordion'>
                  {data.map((item, index) => (
                    <div
                      className={`cdt-accordion ${accordionState.expandedIndices.includes(index) ? 'expanded' : ''}`}
                      key={index}
                    >
                      <div className='cdt-accordion-header' tabIndex={0} onClick={() => toggleAccordion(index)}>
                        <span className='cdt-accordion-title'>{item.filterOptionLabel}</span>
                        <span
                          className={`cdt-icon ${
                            accordionState.expandedIndices.includes(index) ? 'cdt-collapse-icon' : 'cdt-expand-icon'
                          }`}
                        />
                      </div>
                      {accordionState.expandedIndices.includes(index) && (
                        <div className='cdt-accordion-content'>
                          <Checkbox
                            filterOptionList={item.filterOptionList}
                            checkboxStates={accordionState.checkboxStates}
                            onCheckboxToggle={(filterItem: AuthoredFilterT) => toggleCheckbox(filterItem)} // Pass entire filterItem object
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className={`fixed-footer ${showAccordionOverlay ? 'show-footer' : ''}`}>
                  <CdtButton
                    style='button'
                    ariaLabel={authoredData.clearAllButtonAriaLabel}
                    label={authoredData.clearAllButtonCTA}
                    className='cancel-button'
                    onClick={handleMobileClearAll}
                  />
                  <CdtButton
                    style='button'
                    ariaLabel={authoredData.applyFilterButtonMobileAriaLabel}
                    label={authoredData.applyFilterButtonMobileCTA}
                    className='apply-filters-button'
                    onClick={handleToggleMobileAccordion}
                  />
                </div>
              </div>
            </div>,
            document.body,
            'filter-modal-mobile',
          )}
        </>
      }
    />
  );
};

export default Accordion;
