import React, { ReactElement } from 'react';
import CdtButton from '../cdt-button/cdt-button';
import ProjectSelector from '../cdt-thumbnails/cdt-thumbnails';
import Responsive from '../cdt-responsive';
import { GetDataResource } from '../../../../shared/hooks/use-get-data';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { useProjectContext } from '../../../../shared/context/project.context';
import type { AuthoredDataT } from '../../../../shared/types/types';

function Layout1(): ReactElement {
  const [authoredDataResource] = useLoaderData() as [GetDataResource<AuthoredDataT> | null, AbortController];
  const location = useLocation();
  const navigate = useNavigate();

  const authoredData = authoredDataResource?.read();

  const [project] = useProjectContext() ?? [];
  const projectName =
    project?.itemTitle?.toLowerCase() ?? authoredData?.itemSelector[0].itemTitle?.toLowerCase() ?? 'dresser';

  if (!authoredData) return <></>;
  if (location.pathname === '/') {
    navigate(`/${authoredData.stepTitle1?.replaceAll(' ', '-').toLowerCase()}/${projectName}`);
  }

  return (
    <div className='cdt-step-one-container'>
      <span className='cmp-page-title'>{authoredData.title}</span>
      <div>
        <p className='cmp-page-description'>{authoredData.description}</p>
      </div>
      <p className='cmp-page-secondary-title'>{authoredData.secondaryTitle}</p>

      {authoredData.itemSelector && (
        <Responsive
          minWidth={768}
          maxWidth={3400}
          desktopContent={<ProjectSelector itemSelector={authoredData.itemSelector} />}
          mobileContent={<ProjectSelector itemSelector={authoredData.itemSelector} />}
        />
      )}

      <div className='cbg-step-one-button'>
        <CdtButton
          style='link'
          label={authoredData.step1ButtonTitle}
          ariaLabel={authoredData.stepTitle2Aria}
          href={`/${authoredData.stepTitle2?.toLowerCase().replaceAll(' ', '-')}/${projectName}`}
          icon='mwarrow_desktop'
          className='cbg-cdt-button'
        />
      </div>
    </div>
  );
}

export default Layout1;
