import React, { KeyboardEvent, ReactElement, useContext, useEffect, useState } from 'react';
import './cdt-color-swatch.scss';
import { AuthoredDataMultifieldT, AuthoredDataT } from '../../../../shared/types/types';
import { Link } from 'react-router-dom';
import {
  SelectedColorFamilyContext,
  SelectedColorFamilyDispatchContext,
} from '../../../../shared/context/selectedColorFamily.context';

export interface CdtColorSwatchProps {
  authoredData: AuthoredDataT;
  projectName: string;
}

interface ColorFamilyProps {
  colorFamily: Omit<AuthoredDataMultifieldT, 'itemTitle'> & {
    colorFamilyName: string;
    empty: boolean;
  };
  to: string;
}

const CdtColorSwatch: React.FC<CdtColorSwatchProps> = ({ authoredData, projectName }) => {
  return (
    <div className='cdt-color-swatch-container'>
      {authoredData.colorFamilySelector.map((colorFamily, i) => (
        <ColorFamily
          colorFamily={colorFamily}
          to={`/${authoredData.stepTitle3?.toLowerCase().replaceAll(' ', '-')}/${projectName}`}
          key={i}
        />
      ))}
    </div>
  );
};
export default CdtColorSwatch;

function ColorFamily({ colorFamily, to }: ColorFamilyProps): ReactElement {
  const [selectState, setSelectState] = useState(false);
  const selectedColorFamily = useContext(SelectedColorFamilyContext);
  const dispatch = useContext(SelectedColorFamilyDispatchContext);

  useEffect(() => {
    if (colorFamily.colorFamilyName && selectedColorFamily?.colorFamilyName) {
      setSelectState(colorFamily.colorFamilyName === selectedColorFamily?.colorFamilyName);
    }
  }, [colorFamily.colorFamilyName, selectedColorFamily?.colorFamilyName]);

  const handleButtonClick = () => {
    if (!dispatch) return;
    dispatch({
      type: 'ADD_SELECTED_COLOR_FAMILY',
      colorFamily: colorFamily,
    });
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLElement>) => {
    if (e.code === 'Enter') {
      if (!dispatch) return;
      dispatch({
        type: 'ADD_SELECTED_COLOR_FAMILY',
        colorFamily: colorFamily,
      });
    }
  };

  return (
    <Link
      to={to}
      className={`cdt-color-swatch ${selectState ? 'cdt-color-swatch--selected' : ''}`}
      onClick={handleButtonClick}
      onKeyUp={handleKeyUp}
    >
      <span className='cdt-color-swatch__image' style={{ backgroundImage: `url(${colorFamily.fileReference})` }} />
      <span className='cdt-color-swatch__title'>{`${colorFamily.colorFamilyName}`}</span>
    </Link>
  );
}
