// FilterAuthoredDataContext.tsx
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { AuthoredDataT } from "../../shared/types/types";

interface FilterAuthoredDataContextType {
  cloneAuthoredData?: AuthoredDataT; // Allow undefined as initial value
  updateAuthoredData: (data: AuthoredDataT | ((prevData: AuthoredDataT | undefined) => AuthoredDataT)) => void; 
}

// Create initial context data
const initialFilter: FilterAuthoredDataContextType = {
  cloneAuthoredData: undefined,
  updateAuthoredData: (data: AuthoredDataT | ((prevData: AuthoredDataT | undefined) => AuthoredDataT)) => { return data}, // Corrected function signature
};
// Create the context
const FilterAuthoredDataContext = createContext<FilterAuthoredDataContextType>(initialFilter);

// Provider component to wrap around the application
export const FilterAuthoredDataProvider = ({ children }: { children: ReactNode }) => {
  const [cloneAuthoredData, setCloneAuthoredData] = useState<AuthoredDataT | undefined>(initialFilter.cloneAuthoredData);

  // Function to update authoredData
  const updateAuthoredData = (newData: AuthoredDataT | ((prevData: AuthoredDataT | undefined) => AuthoredDataT)) => {
    if (typeof newData === 'function') {
      setCloneAuthoredData((prevData) => {
        if (!prevData) return newData(undefined);
        return newData(prevData);
      });
    } else {
      setCloneAuthoredData(newData);
    }
  };

  // Construct the value object to pass to the provider
  const value: FilterAuthoredDataContextType = {
    cloneAuthoredData,
    updateAuthoredData,
  };

  return (
    <FilterAuthoredDataContext.Provider value={value}>
      {children}
    </FilterAuthoredDataContext.Provider>
  );
};

// Custom hook to consume the context
export const useFilterAuthoredData = () => {
  const context = useContext(FilterAuthoredDataContext);
  if (!context) {
    throw new Error('useFilterAuthoredData must be used within a FilterAuthoredDataProvider');
  }
  return context;
};
