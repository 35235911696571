import React from 'react';
import './cdt-cdp-articles.scss';
import type { AuthoredDataT } from '../../../../shared/types/types';
import CDPArticle from './cdp-article';

export interface CdtCDPArticlesProps {
  authoredData: AuthoredDataT;
}

const CdtCDPArticles: React.FC<CdtCDPArticlesProps> = ({ authoredData }) => {
  return (
    <div className='cdt-cdp-articles'>
      <div className='cdt-cdp-articles-title'>{authoredData.articleTitle}</div>
      <div className='cdt-cdp-articles-container'>
        {(authoredData.articleItemsLink ?? []).map((article, i) => (
          <CDPArticle article={article} key={`cdp-article-${i}`} />
        ))}
      </div>
    </div>
  );
};
export default CdtCDPArticles;
