import React, { useEffect } from 'react';
import './cdt-did-you-know.scss';
import type { AuthoredDataT, ColorsT, ProductT } from '../../../../shared/types/types';
import CdtImageText from '../cdt-image-text/cdt-image-text';

export interface CdtDidYouKnowProps {
  authoredData: AuthoredDataT;
  product: ProductT | null;
  color: ColorsT;
}

const CdtDidYouKnow: React.FC<CdtDidYouKnowProps> = ({ authoredData, product, color }) => {
  const { didYouKnowTitle, didYouKnowItems } = authoredData;
  const filteredDidYouKnow = didYouKnowItems.find((item) => {
    return color.associatedProducts?.[0].productNumber === item.type;
  });

  if (!filteredDidYouKnow) return <></>;

  return (
    <div className='cdt-did-you-know'>
      <div className='cdt-did-you-know__title'>{didYouKnowTitle}</div>
      <CdtImageText
        {...filteredDidYouKnow}
        fileReference={
          filteredDidYouKnow.fileReference ??
          product?.productImage.replace('w=300', 'w=115').replace('h=300', 'h=120') ??
          ''
        }
        fileReferenceMobile={filteredDidYouKnow.fileReferenceMobile ?? product?.productImage ?? ''}
        description={filteredDidYouKnow.description ?? product?.productDescription ?? ''}
        inside={filteredDidYouKnow?.inside === 'true'}
      />
    </div>
  );
};
export default CdtDidYouKnow;
