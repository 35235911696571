import React, { createContext, useReducer } from 'react';
import { ColorsT } from "../types/types";

export const SelectedColorContext = createContext<ColorsT | null>(null);
export const SelectedColorDispatchContext = createContext<React.Dispatch<Action> | null>(null);

export type Action =
  { type: 'ADD_SELECTED_COLOR', color: ColorsT | null }
  | { type: 'REMOVE_SELECTED_COLOR' }

interface SelectedColorProviderProps {
  children: React.ReactNode;
}

export const SelectedColorProvider: React.FC<SelectedColorProviderProps> = ({ children }) => {
  // I cannot figure out the correct type here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedColor, dispatch] = useReducer<React.Reducer<any, Action>>(
    selectedColorReducer,
    null,
  );

  function selectedColorReducer (color: ColorsT, action: Action) {
    switch (action.type) {
      case 'ADD_SELECTED_COLOR': {
        return { ...action.color }
      }
      case 'REMOVE_SELECTED_COLOR': {
        return null
      }
      default: {
        // @ts-ignore
        throw Error('Unknown action: ' + action.type)
      }
    }
  }

  return (
    <SelectedColorContext.Provider value={selectedColor}>
      <SelectedColorDispatchContext.Provider value={dispatch}>
        {children}
      </SelectedColorDispatchContext.Provider>
    </SelectedColorContext.Provider>
  )
}