import React from 'react';
import { ProjectProvider } from './project.context';
import { FavoriteProvider } from './favorite.context';
import { SelectedColorProvider } from "./selectedColor.context";
import { SelectedColorFamilyProvider } from './selectedColorFamily.context';
import { GetDataResource } from '../hooks/use-get-data';
import type {
  ColorDataT,
} from '../types/types';

interface GlobalStateProps {
  children: React.ReactNode,
  colorResource: [GetDataResource<ColorDataT> | null, AbortController],
}

export const GlobalStateProvider: React.FC<GlobalStateProps> = ({ children, colorResource }) => {
   // get color data
   const colorData = colorResource[0]?.read();

   if (!colorData) return <ProjectProvider>{children}</ProjectProvider>;

  return (
    <ProjectProvider>
      <SelectedColorFamilyProvider>
        <FavoriteProvider colorData={colorData} >
          <SelectedColorProvider>
            {children}
          </SelectedColorProvider>
        </FavoriteProvider>
      </SelectedColorFamilyProvider>
    </ProjectProvider>
  )
}