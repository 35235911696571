import React, { createContext, useReducer } from 'react';
import { ColorFamilyT } from '../types/types';

export const SelectedColorFamilyContext = createContext<ColorFamilyT | null>(null);
export const SelectedColorFamilyDispatchContext = createContext<React.Dispatch<Action> | null>(null);

type Action =
  | { type: 'ADD_SELECTED_COLOR_FAMILY'; colorFamily: ColorFamilyT | null }
  | { type: 'REMOVE_SELECTED_COLOR_FAMILY' };

interface SelectedColorFamilyProviderProps {
  children: React.ReactNode;
}

export const SelectedColorFamilyProvider: React.FC<SelectedColorFamilyProviderProps> = ({ children }) => {
  // I cannot figure out the correct type here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedColorFamily, dispatch] = useReducer<React.Reducer<any, Action>>(selectedColorFamilyReducer, null);

  function selectedColorFamilyReducer(selectedColorFamily: ColorFamilyT | null, action: Action) {
    switch (action.type) {
      case 'ADD_SELECTED_COLOR_FAMILY': {
        return { ...action.colorFamily };
      }
      case 'REMOVE_SELECTED_COLOR_FAMILY': {
        return null;
      }
      default: {
        // @ts-ignore
        throw Error('Unknown action: ' + action.type);
      }
    }
  }

  return (
    <SelectedColorFamilyContext.Provider value={selectedColorFamily}>
      <SelectedColorFamilyDispatchContext.Provider value={dispatch}>
        {children}
      </SelectedColorFamilyDispatchContext.Provider>
    </SelectedColorFamilyContext.Provider>
  );
};
