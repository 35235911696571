import React, { createContext, useContext, useState } from 'react';
import { AuthoredDataMultifieldT } from "../types/types";

const ProjectContext =
  createContext<[Omit<AuthoredDataMultifieldT, "colorFamilyTag"> | null, React.Dispatch<React.SetStateAction<Omit<AuthoredDataMultifieldT, "colorFamilyTag"> | null>>] | null>(null);

interface ProjectProviderProps {
  children: React.ReactNode;
}

export const ProjectProvider: React.FC<ProjectProviderProps> = ({ children }) => {
  const [project, setProject] = useState<Omit<AuthoredDataMultifieldT, 'colorFamilyTag'> | null>(null);

  return (
    <ProjectContext.Provider value={[project, setProject]}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => useContext(ProjectContext);