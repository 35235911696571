import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import './color-demonstration-tool.scss';
import CdtCarouselBackgroundContainer from './cdt-carousel/cdt-carousel-background-container';
import CdtColorBar from './cdt-color-bar/cdt-color-bar';
import ColorDemonStrationToolFilter from './color-demonstration-tool-filter/color-demonstration-tool-filter';
import { useFilterAuthoredData } from '../../../shared/context/filter.context';
import { useLoaderData } from 'react-router-dom';
import type { AuthoredDataFilterT, AuthoredDataT, AuthoredFilterT, ColorDataT } from '../../../shared/types/types';
import type { GetDataResource } from '../../../shared/hooks/use-get-data';

function ColorDemonstrationTool(): ReactElement | null {
  const [detailsElement, setDetailsElement] = useState<HTMLDivElement | null>(null);
  const detailsRef = useRef<HTMLDivElement | null>(null);
  const [[authoredDataResource], [colorDataResource]] = useLoaderData() as [
    [GetDataResource<AuthoredDataT> | null, AbortController],
    [GetDataResource<ColorDataT> | null, AbortController],
  ];
  const { cloneAuthoredData, updateAuthoredData } = useFilterAuthoredData();
  const authoredData = authoredDataResource?.read();
  const colorData = colorDataResource?.read();

  const modifyFilters = (data: AuthoredDataT | null | undefined): AuthoredDataT | null | undefined => {
    const generateUniqueId = (filterLabel: AuthoredDataFilterT, filterOption: AuthoredFilterT): string => {
      return `${filterLabel.filterOptionLabel}_${filterOption.filterTag}`;
    };
    const initializeSearchInStep = () => {
      return { colorFamilySearch: undefined };
    };
    let updatedData = { ...data };
    updatedData.filters?.forEach((filter: AuthoredDataFilterT) => {
      filter.filterOptionList.forEach((option: AuthoredFilterT) => {
        const filterId = generateUniqueId(filter, option);
        Object.assign(option, {
          checkBoxSelected: false,
          filterID: filterId,
          parentFilter: filter?.filterOptionLabel,
        });
      });
    });
    updatedData = {
      ...updatedData,
      searchInStep: initializeSearchInStep(),
    };
    return updatedData as AuthoredDataT;
  };

  useEffect(() => {
    const rawData = modifyFilters(authoredData);
    if (rawData === null || rawData === undefined) {
      console.error('Authored data is null or undefined');
      return;
    }
    if (cloneAuthoredData === undefined) {
      updateAuthoredData(rawData);
    }
  }, [authoredData, cloneAuthoredData, updateAuthoredData]);

  const init = useCallback(() => {
    if (detailsRef.current) {
      setDetailsElement(detailsRef.current);
    } else {
      setTimeout(() => init(), 100);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  if (!authoredData || !colorData) return null;

  return (
    <div className='cmp-color-demonstration-tool'>
      <CdtColorBar authoredData={authoredData} colors={colorData.colors} />
      <ColorDemonStrationToolFilter authoredData={authoredData} ref={detailsRef} />
      <CdtCarouselBackgroundContainer
        authoredData={authoredData}
        colors={colorData.colors}
        detailsElement={detailsElement}
      />
    </div>
  );
}

export default ColorDemonstrationTool;
