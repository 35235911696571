// Responsive.tsx
import React, { useEffect, useState } from 'react';

interface ResponsiveProps {
  minWidth: number;
  maxWidth: number;
  desktopContent: React.ReactNode;
  mobileContent: React.ReactNode;
}

const Responsive: React.FC<ResponsiveProps> = ({ minWidth, maxWidth, desktopContent, mobileContent }) => {
  const [isDesktop, setIsDesktop] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      const isWithinRange = currentWidth >= minWidth && currentWidth <= maxWidth;
      setIsDesktop(isWithinRange);
    };

    // Initial check on mount
    handleResize();

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [minWidth, maxWidth]);

  if (isDesktop) {
    return <>{desktopContent}</>;
  } else {
    return <>{mobileContent}</>;
  }
};

export default Responsive;
