import React, { ReactElement, Suspense, useEffect, useState } from 'react';
import CdtColorDetailsContainer from './cdt-color-details/cdt-color-details-container';
import ColorDemonstrationTool from './color-demonstration-tool';
import ColorFilterByProduct from '../../../shared/utils/colorFilterByProduct';
import Spinner from '../../../shared/componets/spinner';
import Step1 from './color-demonstration-tool-filter-layouts/step1';
import Step2 from './color-demonstration-tool-filter-layouts/step2';
import Step3 from './color-demonstration-tool-filter-layouts/step3';
import useGetData, { type GetDataResource } from '../../../shared/hooks/use-get-data';
import { FilterAuthoredDataProvider } from '../../../shared/context/filter.context';
import { GlobalStateProvider } from '../../../shared/context/global.context';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import type { AuthoredDataT, ColorDataT, ProductT } from '../../../shared/types/types';
import ErrorBanner from './cdt-error-banner/cdt-error-banner';

interface ColorDemonstrationToolContainerInnerT {
  authorResource: [GetDataResource<AuthoredDataT> | null, AbortController];
}

const checkFields = (data: any): boolean => {
  const fieldsToCheck = [
    'colorDataURL',
    'productDataURL',
    'stepTitle1',
    'stepTitle2',
    'stepTitle3',
    'colorDetailTitle',
    'colorVariationTitle',
    'searchResultText',
    'totalSearchResult'
  ];

  for (const field of fieldsToCheck) {
    if (!data[field] || data[field].trim() === '') {
      return false;
    }
  }
  return true;
};

export interface ColorDemonstrationToolContainerT {
  slingModelAPI: string | undefined;
}

const ColorDemonstrationToolContainerInner = ({
  authorResource,
}: ColorDemonstrationToolContainerInnerT): ReactElement | null => {
  const [step1Segment, setStep1Segment] = useState('');
  const [step2Segment, setStep2Segment] = useState('');
  const [step3Segment, setStep3Segment] = useState('');
  const [cdpSegment, setCdpSegment] = useState('');
  const [router, setRouter] = useState<ReturnType<typeof createHashRouter> | null>(null);
  const [showErrorBanner, setShowErrorBanner] = useState(false);

  const authoredData = authorResource[0]?.read();

  const colorResource = useGetData<ColorDataT>(authoredData?.colorDataURL ?? '', ColorFilterByProduct);
  const productResource = useGetData<ProductT>(authoredData?.productDataURL ?? '');

  useEffect(() => {
    if (!authoredData || !checkFields(authoredData)) {
      setShowErrorBanner(true);
      return;
    }

    setStep1Segment(authoredData.stepTitle1?.replaceAll(' ', '-').toLowerCase());
    setStep2Segment(authoredData.stepTitle2?.replaceAll(' ', '-').toLowerCase());
    setStep3Segment(authoredData.stepTitle3?.replaceAll(' ', '-').toLowerCase());
    setCdpSegment(authoredData?.colorDetailTitle?.replaceAll(' ', '-').toLowerCase());

    setShowErrorBanner(false);
  }, [authoredData]);

  useEffect(() => {
    if (step1Segment && step2Segment && step3Segment && cdpSegment) {
      setRouter(
        createHashRouter([
          {
            path: '/',
            loader: () => [authorResource, colorResource],
            element: (
              <Suspense fallback={<Spinner full />}>
                <GlobalStateProvider colorResource={colorResource}>
                  <ColorDemonstrationTool />
                </GlobalStateProvider>
              </Suspense>
            ),
            children: [
              {
                path: ``,
                loader: () => authorResource,
                element: <Step1 />,
              },
              {
                path: `${step1Segment}`,
                loader: () => authorResource,
                element: <Step1 />,
              },
              {
                path: `${step1Segment}/:projectName`,
                loader: () => authorResource,
                element: <Step1 />,
              },
              {
                path: `${step2Segment}`,
                loader: () => authorResource,
                element: <Step2 />,
              },
              {
                path: `${step2Segment}/:projectName`,
                loader: () => authorResource,
                element: <Step2 />,
              },
              {
                path: `${step3Segment}`,
                loader: () => [authorResource, colorResource, productResource],
                element: <Step3 />,
              },
              {
                path: `${step3Segment}/:projectName`,
                loader: () => [authorResource, colorResource, productResource],
                element: <Step3 />,
              },
            ],
          },
          {
            path: `/${cdpSegment}/:mdmId`,
            loader: () => [authorResource, colorResource, productResource],
            element: (
              <Suspense fallback={<Spinner full />}>
                <GlobalStateProvider colorResource={colorResource}>
                  <CdtColorDetailsContainer />
                </GlobalStateProvider>
              </Suspense>
            ),
          },
          {
            path: `/${cdpSegment}/:projectName/:mdmId`,
            loader: () => [authorResource, colorResource, productResource],
            element: (
              <Suspense fallback={<Spinner full />}>
                <GlobalStateProvider colorResource={colorResource}>
                  <CdtColorDetailsContainer />
                </GlobalStateProvider>
              </Suspense>
            ),
          },
        ]),
      );
    }
    // Adding the resources to the dependencies was causing this to infinitely loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cdpSegment, step1Segment, step2Segment, step3Segment]);
  if (!authoredData) return <></>;

  return (
    <>
      {showErrorBanner && (
        <ErrorBanner
          message="Missing crucial data in Authoring. Color visualizer may not load or function accurately"
          onMoreInfo={() => console.error('More info clicked')}
          onOk={() => setShowErrorBanner(false)}
        />
      )}
      {router && <RouterProvider router={router} />}
    </>
  );
};

const ColorDemonstrationToolContainer = ({ slingModelAPI }: ColorDemonstrationToolContainerT): ReactElement | null => {
  const authorResource = useGetData<AuthoredDataT>(`${slingModelAPI}.model.json`);
  return (
    <Suspense fallback={<Spinner full />}>
      <FilterAuthoredDataProvider>
        <ColorDemonstrationToolContainerInner authorResource={authorResource} />
      </FilterAuthoredDataProvider>
    </Suspense>
  );
};
export default ColorDemonstrationToolContainer;
