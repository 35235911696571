import React, { useEffect, useState } from 'react';
import './cdt-color-card.scss';
import CdtButton from '../cdt-button/cdt-button';
import CdtFavorite from '../cdt-favorite/cdt-favorite';
import { useProjectContext } from '../../../../shared/context/project.context';
import type { AuthoredDataT, ColorsT } from '../../../../shared/types/types';

export interface CdtColorCardProps {
  color: ColorsT;
  productName: string;
  authoredData: AuthoredDataT;
  favorite?: boolean;
  favoriteCallback?: (color: ColorsT, favorite: boolean, settings?: { channelId: number; localeId: number }) => void;
  settingsData?: {
    channelId: number;
    localeId: number;
  };
}

const CdtColorCard: React.FC<CdtColorCardProps> = ({
  color,
  productName,
  authoredData,
  favorite = false,
  favoriteCallback,
  settingsData,
}) => {
  const [project] = useProjectContext() ?? [];
  const [projectName, setProjectName] = useState('');

  const handleFavoriteClick = () => {
    if (favoriteCallback) favoriteCallback(color, favorite, settingsData);
  };

  useEffect(() => {
    setProjectName(
      project?.itemTitle?.toLowerCase() ?? authoredData?.itemSelector[0].itemTitle?.toLowerCase() ?? 'dresser',
    );
  }, [authoredData?.itemSelector, project?.itemTitle]);

  return (
    <div className='cdt-color-card'>
      <CdtFavorite favorite={favorite} onClick={handleFavoriteClick} hideFavorite={authoredData.hideFavorites} />
      <div className='cdt-color-card-main'>
        <div
          className='cdt-color-card-image'
          style={{
            backgroundImage: `url(${color.associatedAssets.find((aa) => aa.type === 'ATT_Carousel_Image_01')?.url})`,
          }}
        />
        <div className='cdt-color-card-info'>
          <p className='cdt-color-card-title'>{`${color.colorName} ${color.colorNumber}`}</p>
          <p className='cdt-color-card-product-name'>{productName}</p>
          <p className='cdt-color-card-opacity'>{color.transparency}</p>
        </div>
      </div>

      <div className='cdt-color-card-cta'>
        <CdtButton
          style='link'
          label={authoredData.colorCardButtonCTA}
          ariaLabel={authoredData.colorCardButtonAria}
          href={`/${authoredData?.colorDetailTitle?.toLowerCase().replaceAll(' ', '-')}/${projectName}/${color.mdmId}`}
          icon='mwarrow_desktop'
          className='cbg-cdt-button'
        />
      </div>
    </div>
  );
};

export default CdtColorCard;
