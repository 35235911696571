import { ColorDataT } from '../types/types';

const productsToFilterOut: string[] = [];

const ColorFilterByProduct = (colorData: ColorDataT) => {
  if (colorData && 'channelId' in colorData) {
    return {
      ...colorData,
      colors: colorData.colors
        .map((color) => ({
          ...color,
          // Filter Associated Products that dont work with the Color Visualizer
          associatedProducts: color.associatedProducts?.filter(
            (product) => !productsToFilterOut.includes(product.productNumber),
          ),
        }))
        // Filter colors with no Associated Products
        .filter((color) => color.associatedProducts && color.associatedProducts.length > 0),
    };
  } else {
    return colorData;
  }
};

export default ColorFilterByProduct;
