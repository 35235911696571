import React, { KeyboardEvent, MouseEvent, useContext, useEffect, useState } from 'react';
import './cdt-wall-container.scss';
import CdtColorCard from '../cdt-color-card/cdt-color-card';
import CdtExpandButton from '../cdt-expand-button/cdt-expand-button';
import useFavorite, { handleFavoriteCallback, isFavorite } from '../../../../shared/hooks/use-favorite';
import type { AuthoredDataT, ColorsT, ProductT } from '../../../../shared/types/types';
import { SelectedColorContext, SelectedColorDispatchContext } from '../../../../shared/context/selectedColor.context';

export interface WallContainerProps {
  colorData: ColorsT[];
  authoredData: AuthoredDataT;
  productData: ProductT[];
  searchKey?: string | null;
  settingsData: {
    channelId: number;
    localeId: number;
  };
}

const WallContainer: React.FC<WallContainerProps> = ({ colorData, authoredData, productData, searchKey, settingsData }) => {
  const [displayCount, setDisplayCount] = useState(12);
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const favorites = useFavorite();
  const selectedColor = useContext(SelectedColorContext);
  const dispatchSelectedColor = useContext(SelectedColorDispatchContext);

  const handleButtonClick = () => {
    setDisplayCount(displayCount + 12);
  };

  const checkMobileView = () => {
    const mobileView = window.innerWidth <= 799;
    setIsMobileView(mobileView);
  };

  const scrollToCarouselTop = () => {
    const carousel = document.querySelector('.cmp-color-demonstration-tool .cdt-carousel');
    if (carousel) {
      carousel.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  const handleColorClick = (_: MouseEvent<HTMLElement>, mdmId: string) => {
    scrollToCarouselTop();
    if (dispatchSelectedColor) {
      dispatchSelectedColor({
        type: 'ADD_SELECTED_COLOR',
        color: colorData.find((color) => color.mdmId === mdmId) ?? null,
      });
      
    }
  };

  const handleColorOnKeyUp = (e: KeyboardEvent<HTMLElement>, mdmId: string) => {
    switch (e.code) {
      case 'Enter':
        scrollToCarouselTop();
        if (dispatchSelectedColor) {
          dispatchSelectedColor({
            type: 'ADD_SELECTED_COLOR',
            color: colorData.find((color) => color.mdmId === mdmId) ?? null,
          });
        }
        break;
      default:
        return null;
    }
  };

  const searchResultValue = (): JSX.Element | string => {
    if (authoredData.searchResultText && Array.isArray(colorData)) {
      // not sure what to name these variables. Will have to go with these for now.
      // %temp% is for the results text.
      const tempReplacedText = authoredData.searchResultText?.replace('%temp%', '%replaceTemp%');

      // %key% is for the color search Key.
      const keyParts = tempReplacedText.split('%key%');

      const processedParts = keyParts.map((part) => {
        const tempParts = part.split('%replaceTemp%');
        if (tempParts.length === 2) {
          const wordAfterTempMatch = tempParts[1].match(/\s*(\w+)/);
          const wordAfterTemp = wordAfterTempMatch ? wordAfterTempMatch[1] : '';

          return (
            <>
              <div className='cdt-search-result-container'>
                {tempParts[0]}
                <span className='cdt-bold'>
                  {colorData.length} {wordAfterTemp}
                </span>
                <span className='cdt-searchKey'>{tempParts[1].replace(/\s*(\w+)/, '')}</span>
              </div>
            </>
          );
        } else {
          return part;
        }
      });

      return (
        <>
          {processedParts.reduce((prev, curr, index) => (
            <>
              {prev}
              {index > 0 && <span>{searchKey}.</span>}
              {curr}
            </>
          ))}
        </>
      );
    }
    return '';
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener('resize', checkMobileView);
    return () => {
      window.removeEventListener('resize', checkMobileView);
    };
  }, []);

  const visibleColors = colorData.slice(0, displayCount);
  const remainingColors = colorData.length - displayCount;
  // Todo : Get "Sorry, we found" from Authoring.
  const tempTotalSearch = authoredData.totalSearchResult?.replace('%temp%', colorData.length.toString());
  return (
    <div className='cdt-wall-container'>
      {colorData.length !== 0 && <div className='cdt-results-length'>{tempTotalSearch}</div>}
      <div className='cdt-wall-card-container'>
        {colorData.length === 0 && <div className='cdt-no-results'>{searchResultValue()}</div>}
        {(isMobileView ? colorData : visibleColors).map((color) => {
          const isSelected = color.mdmId === selectedColor?.mdmId;
          return (
            <div
              className={`cdt-each-card${isSelected ? ' cdt-each-card--selected' : ''}`}
              key={color.id}
              onClick={(e) => handleColorClick(e, color.mdmId)}
              onKeyUp={(e) => handleColorOnKeyUp(e, color.mdmId)}
              tabIndex={0}
            >
              <CdtColorCard
                color={color}
                productName={
                  productData.find((product) => product?.productId === color?.associatedProducts?.[0]?.productNumber)
                    ?.productName ?? ''
                }
                authoredData={authoredData}
                favorite={isFavorite(color, favorites)}
                favoriteCallback={handleFavoriteCallback}
                settingsData={settingsData}
              />
            </div>
          );
        })}
      </div>
      {!isMobileView && remainingColors > 0 && (
        <div className='cdt-view-more-button'>
          <CdtExpandButton
            onClick={handleButtonClick}
            label={authoredData.viewMoreButtonCTA}
            colorCounter={remainingColors}
          />
        </div>
      )}
    </div>
  );
};

export default WallContainer;
