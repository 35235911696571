import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { AuthoredDataT, ColorsT } from '../../../../shared/types/types';
import { useProjectContext } from '../../../../shared/context/project.context';
import { useParams } from 'react-router-dom';
import './cdt-colored-project.scss';

export interface CdtColoredProjectProps {
  authoredData: AuthoredDataT;
  color?: ColorsT | null;
}

const CdtColoredProject: React.FC<CdtColoredProjectProps> = ({ authoredData, color }): ReactElement => {
  const [project, setProject] = useProjectContext() ?? [];
  const [modProject, setModProject] = useState<string | null>(null);
  const [fileNameTemplateM, setFileNameTemplateM] = useState<string | null>(null);
  const [filePathTemplateM, setFilePathTemplateM] = useState<string | null>(null);
  const [fileNameTemplateD, setFileNameTemplateD] = useState<string | null>(null);
  const [filePathTemplateD, setFilePathTemplateD] = useState<string | null>(null);
  const [isError, setError] = useState<Set<string>>(new Set<string>());
  const params = useParams();

  const handleError = useCallback(() => {
    setError((prev) => {
      if (color?.mdmId) {
        return new Set<string>(prev).add(color.mdmId);
      }
      return prev;
    });
  }, [color]);

  useEffect(() => {
    if (!project) {
      if (!modProject) {
        if (!params.projectName) {
          setModProject(authoredData.itemSelector[0].itemTitle?.toLowerCase() ?? 'dresser');
        } else {
          setModProject(params.projectName ?? 'dresser');
        }
      } else {
        if (setProject) {
          setProject(
            authoredData.itemSelector.find((item) => item.itemTitle?.toLowerCase() === modProject) ??
              authoredData.itemSelector[0] ??
              null,
          );
        }
      }
    } else if (params.projectName && params.projectName !== project?.itemTitle?.toLowerCase() && setProject) {
      setProject(null);
      setModProject(null);
    } else {
      // hard coding these as there are the only these options now
      const wood = 'red-oak';
      const shortUsage = ['m', 'd'];
      const usage = ['mobile', 'desktop'];

      const useProjectName = modProject ?? project.itemTitle?.toLowerCase();

      if (color && !isError.has(color.mdmId)) {
        const brand = color?.brand.toLowerCase() ?? 'minwax';
        const transparency = color?.transparency?.toLowerCase() ?? 'solid';
        const colorName = color?.colorName.toLowerCase().replaceAll(' ', '-') ?? 'uncolored';
        const mdmId = color?.mdmId.toLowerCase().replaceAll('_', '-') ?? '';
        const colorFamily = color?.colorFamily.toLowerCase() ?? '';

        // /content/dam/cbg-minwax/visualizer/mobile/minwax/desk/red-oak/reds/minwax-red-oak-semi-transparent-crimson-desk-stc-17839788-m.jpg
        setFileNameTemplateM(
          `${brand}-${wood}-${transparency}-${colorName}-${useProjectName}-${mdmId}-${shortUsage[0]}.jpg`,
        );
        setFilePathTemplateM(
          `/content/dam/cbg-${brand}/visualizer/${usage[0]}/${brand}/${useProjectName}/${wood}/${colorFamily}/`,
        );
        setFileNameTemplateD(
          `${brand}-${wood}-${transparency}-${colorName}-${useProjectName}-${mdmId}-${shortUsage[1]}.jpg`,
        );
        setFilePathTemplateD(
          `/content/dam/cbg-${brand}/visualizer/${usage[1]}/${brand}/${useProjectName}/${wood}/${colorFamily}/`,
        );
      } else {
        // TODO: how do we get this value when no color is selected
        const brand = 'minwax';

        // /content/dam/cbg-minwax/visualizer/mobile/minwax/desk/red-oak/minwax-red-oak-desk-m.jpg
        setFileNameTemplateM(`${brand}-${wood}-${useProjectName}-${shortUsage[0]}.jpg`);
        setFilePathTemplateM(`/content/dam/cbg-${brand}/visualizer/${usage[0]}/${brand}/${useProjectName}/${wood}/`);
        setFileNameTemplateD(`${brand}-${wood}-${useProjectName}-${shortUsage[1]}.jpg`);
        setFilePathTemplateD(`/content/dam/cbg-${brand}/visualizer/${usage[1]}/${brand}/${useProjectName}/${wood}/`);
      }
    }
  }, [authoredData.itemSelector, color, isError, modProject, params.projectName, project, setProject]);

  if (
    filePathTemplateD === null ||
    fileNameTemplateD === null ||
    filePathTemplateM === null ||
    fileNameTemplateM === null
  )
    return <></>;

  return (
    <div>
      <picture>
        <source srcSet={`${filePathTemplateD}${fileNameTemplateD}`} media='(min-width: 800px)' />
        <img
          src={`${filePathTemplateM}${fileNameTemplateM}`}
          alt={`${color?.colorName ?? ''}${project?.altText ? ` ${project?.altText}` : ''}`}
          onError={handleError}
        />
      </picture>
      {color && isError.has(color.mdmId) && (
        <div className='cmp-image-error-message'>
          <span className='error-text'>{authoredData.imageErrorMessage}</span>
        </div>
      )}
    </div>
  );
};

export default CdtColoredProject;
