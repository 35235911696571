import React, { KeyboardEvent, ReactElement } from 'react';
import './cdt-favorite.scss';

export interface CDTFavoriteProps {
  favorite: boolean;
  onClick?: () => void;
  hideFavorite?: boolean;
}

const CdtFavorite: React.FC<CDTFavoriteProps> = ({ favorite, onClick, hideFavorite }): ReactElement => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  const handleOnKeyUp = (e: KeyboardEvent<HTMLElement>) => {
    switch (e.code) {
      case 'Enter':
        if (onClick) onClick();
        break;
      default:
        return null;
    }
  };
  if (hideFavorite) return <></>;
  // These icons are Minwax specific will need to add more logic if other brands have other colors
  return (
    <div
      className={`cdt-favorite ${favorite ? 'cdt-favorite--favorited' : ''}`}
      tabIndex={0}
      onClick={handleClick}
      onKeyUp={handleOnKeyUp}
    >
      <svg className='cdt-favorite__unselected'>
        <use href='/etc.clientlibs/cbg-platform/clientlibs/clientlib-site/resources/fonts/sprite.svg#mwfavorite_unselected' />
      </svg>
      <svg className='cdt-favorite__selected'>
        <use href='/etc.clientlibs/cbg-platform/clientlibs/clientlib-site/resources/fonts/sprite.svg#mwfavorite_select' />
      </svg>
    </div>
  );
};

export default CdtFavorite;
