import React from 'react';
import './cdt-error-banner.scss'

interface ErrorBannerProps {
  message: string;
  onMoreInfo: () => void;
  onOk: () => void;
}

const ErrorBanner: React.FC<ErrorBannerProps> = ({ message, onMoreInfo, onOk }) => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <div className="banner-message">
          <span className="banner-icon">⚠️</span>
          <span className="banner-text">{message}</span>
        </div>
        <div className="banner-buttons">
          {/* <button className="banner-button" onClick={onMoreInfo}>More Info</button> */}
          <button className="banner-button" onClick={onOk}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default ErrorBanner;
