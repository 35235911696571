import React, { useEffect, useState, useContext } from 'react';
import { SelectedColorFamilyContext } from '../../../../shared/context/selectedColorFamily.context';

export interface TabsProps {
  tabs: Array<{
    colorCategoryFilterLabel: string;
    colorCategoryFilterTag?: string[];
    colorCategoryAriaLabel: string
  }>;
  selectedTab: string | null;
  onTabClick: (tabTag: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabs, selectedTab, onTabClick }) => {
  const TAGPREFIX = 'cbg';
  const [mobileDropdownLabel, setMobileDropdownLabel] = useState<string>("");
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState<boolean>(false);
  const [colorFamilyTabTag, setColorFamilyTabTag] = useState<string>("");
  const selectedColorFamily = useContext(SelectedColorFamilyContext);

  const combineTag = (tags : string[] | undefined) => {
    if (typeof tags === "undefined") return "";
    // take array of tags with format 'TAGPREFIX:value' and combined into 'value:value:...'
    return tags.reduce((acc, tag) => `${acc}:${tag.slice(TAGPREFIX.length + 1)}`, '').slice(1);
  }

  const handleTabClick = (tabLabel: string ,tabTags: string) => {
    onTabClick(tabTags);
    setMobileDropdownLabel(tabLabel);
    setMobileDropdownOpen(false);
  }

  useEffect(() => {
    if (tabs.length > 0 && !selectedTab) {
      let firstTabTag;
      if (selectedColorFamily) {
        firstTabTag = combineTag(selectedColorFamily.colorFamilyTag);
        setMobileDropdownLabel(selectedColorFamily.colorFamilyName);
      }
      else {
        firstTabTag = combineTag(tabs[0].colorCategoryFilterTag);
        setMobileDropdownLabel(tabs[0].colorCategoryFilterLabel);
      }
      onTabClick(firstTabTag);
    }
  }, [tabs, selectedTab, onTabClick, selectedColorFamily]);

  useEffect(() => {
    if (!selectedColorFamily) return;
    setColorFamilyTabTag(combineTag(selectedColorFamily.colorFamilyTag));
  }, [selectedColorFamily]);

  return (
    <div className="cdt-tabs-container">
      <div className="cdt-mobile-dropdown-toggle">
        <button
          className='cdt-mobile-dropdown-toggle-button'
          onClick={() => setMobileDropdownOpen(!mobileDropdownOpen)}
          aria-label={`${mobileDropdownLabel} selected`}
        >
          {mobileDropdownLabel}
        </button>
      </div>
      <div className={`cdt-tab-buttons ${mobileDropdownOpen ? "mobile-dropdown-open": "mobile-dropdown-close"}`}>
        {selectedColorFamily ? (
            <button
              className={`cdt-tab-each-button ${selectedColorFamily.colorFamilyName === mobileDropdownLabel ? 'selected' : ''}`}
              onClick={() => handleTabClick(selectedColorFamily.colorFamilyName, colorFamilyTabTag)}
              aria-label={selectedColorFamily.colorFamilyName}
            >
              {selectedColorFamily.colorFamilyName}
            </button>
          ) : (<></>)
        }
        {tabs.map((tab) => (
          <button
            className={`cdt-tab-each-button ${tab.colorCategoryFilterLabel === mobileDropdownLabel ? 'selected' : ''}`}
            key={tab.colorCategoryFilterLabel}
            onClick={() => handleTabClick(tab.colorCategoryFilterLabel, combineTag(tab.colorCategoryFilterTag))}
            aria-label={tab.colorCategoryAriaLabel}
          >
            {tab.colorCategoryFilterLabel}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Tabs;