import { useEffect, useState } from 'react';
import { CartAPIFavoriteT, ColorsT } from '../types/types';

export const addFavorite = (color: ColorsT, settings?: { channelId: number; localeId: number }) => {
  const colorWithSettings = {
    ...color,
    channelId: settings?.channelId,
    localeId: settings?.localeId
  };
  const customEvent = new CustomEvent('ADD_FAVORITE_REACT', { 
    detail: { color: colorWithSettings } 
  });
  window.dispatchEvent(customEvent);
};

export const removeFavorite = (colorId?: string) => {
  if (!colorId) return null;
  const customEvent = new CustomEvent('REMOVE_FAVORITE_REACT', { detail: { colorId } });
  window.dispatchEvent(customEvent);
};

export const handleFavoriteCallback = (
  color: ColorsT, 
  favorite: boolean,
  settings?: { channelId: number; localeId: number }
) => {
  if (favorite) {
    removeFavorite(color.mdmId);
  } else {
    addFavorite(color, settings);
  }
};

export const isFavorite = (color: ColorsT, favorites: CartAPIFavoriteT[]) => {
  return favorites.reduce((a, c) => {
    if (c.mdmColorId === color.mdmId || c.mdmId === color.mdmId) {
      return true;
    } else {
      return a;
    }
  }, false);
};

const useFavorite = (): CartAPIFavoriteT[] => {
  const [favorites, setFavorites] = useState<CartAPIFavoriteT[]>([]);
  const [trigger, setTrigger] = useState<boolean>(false);

  window.addEventListener('LOCAL_FAVORITE_UPDATE', () => {
    setTrigger(!trigger);
  });

  useEffect(() => {
    // get favorites from local
    const favoritesLocal = localStorage.getItem('favorites');
    if (!favoritesLocal) return;
    setFavorites(JSON.parse(favoritesLocal));
  }, [trigger]);

  return favorites;
};

export default useFavorite;
