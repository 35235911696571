import React, { ReactElement, useEffect, useState } from 'react';
import type { AuthoredDataT } from '../../../../shared/types/types';
import CdtColorSwatch from '../cdt-color-swatch/cdt-color-swatch';
import Search from '../cdt-search/cdt-search';
import { GetDataResource } from '../../../../shared/hooks/use-get-data';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useProjectContext } from '../../../../shared/context/project.context';
import { useFilterAuthoredData } from '../../../../shared/context/filter.context';

function Layout2(): ReactElement {
  const [authoredDataResource] = useLoaderData() as [GetDataResource<AuthoredDataT> | null, AbortController];

  const authoredData = authoredDataResource?.read();
  const { cloneAuthoredData, updateAuthoredData } = useFilterAuthoredData();
  const [searchKey, setSearchKey] = useState<string | null>(null);
  const [project] = useProjectContext() ?? [];
  const projectName =
    project?.itemTitle?.toLowerCase() ?? authoredData?.itemSelector[0].itemTitle?.toLowerCase() ?? 'dresser';
  const navigate = useNavigate();
  const handleSearchKey = (onSearch: string) => {
    // Update the authoredData object
    const updatedSearchInStep = {
      ...cloneAuthoredData?.searchInStep,
      colorFamilySearch: onSearch,
    };
    const updatedCloneAuthoredData = { ...cloneAuthoredData, searchInStep: updatedSearchInStep };
    updateAuthoredData(updatedCloneAuthoredData as AuthoredDataT);

    setSearchKey(onSearch);
  };
  useEffect(() => {
    // Navigate using useNavigate when searchKey is updated
    if (searchKey !== null) {
      navigate(`/${authoredData?.stepTitle3?.toLowerCase().replaceAll(' ', '-')}/${projectName}`);
    }
  }, [authoredData?.stepTitle3, navigate, projectName, searchKey]);

  if (!authoredData) return <></>;

  return (
    <div className='cmp-step-two-container'>
      <div className='cmp-color-family-title-container'>
        <h3 className='color-family-title'>{authoredData.colorFamilyTitle}</h3>
      </div>
      <div className='cmp-color-family-search-container'>
        <Search label={authoredData.searchLabel} onSearch={handleSearchKey} searchButtonAriaLabel={authoredData.searchButtonAriaLabel} clearSearchButtonAriaLabel={authoredData.clearSearchButtonAriaLabel} />
      </div>
      <CdtColorSwatch authoredData={authoredData} projectName={projectName} />
    </div>
  );
}

export default Layout2;
