import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import ColorDemonstrationToolContainer from './color-demonstration-tool-container'

const container: HTMLElement | null = document.querySelector('.cmp-color-demonstration-tool-root')

if (container) {
  const slingModelAPI = container.dataset.resourcePath
  const root = createRoot(container)
  root.render(
    <StrictMode>
      <ColorDemonstrationToolContainer slingModelAPI={slingModelAPI} />
    </StrictMode>,
  )
}
