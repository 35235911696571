import React from 'react';
import type { AuthoredFilterT } from '../../../../shared/types/types';
import './cdt-checkbox.scss';

export interface CheckboxProps {
  filterOptionList: AuthoredFilterT[];
  checkboxStates: Record<string, boolean>;
  onCheckboxToggle: (filterItem: AuthoredFilterT) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ filterOptionList, checkboxStates, onCheckboxToggle }) => {
  const handleCheckboxKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
    filterItem: AuthoredFilterT,
  ) => {
    const handleCheckboxToggle = () => {
      onCheckboxToggle(filterItem);
    };

    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        event.stopPropagation();
        handleCheckboxToggle();
        break;
      case ' ':
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  return (
    <div className='cdt-checkbox-container'>
      {filterOptionList.map((filterItem, index) => (
        <label key={index} className='cdt-filter-checkbox__label'>
          <input
            type='checkbox'
            tabIndex={0}
            value={filterItem.filterTag}
            name={filterItem.filterLabel}
            checked={checkboxStates[filterItem.filterTag] || false}
            onChange={() => onCheckboxToggle(filterItem)}
            onKeyDown={(event) => handleCheckboxKeyDown(event, index, filterItem)}
          />
          <span className='filter-checkbox__span' />
          <span className='filter-checkbox__brand-name'>{filterItem.filterLabel}</span>
        </label>
      ))}
    </div>
  );
};

export default Checkbox;
