import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import CdtColoredProject from '../cdt-colored-project/cdt-colored-project';
import CdtCarousel from './cdt-carousel';
import type { AuthoredDataT, CartAPIFavoriteT, ColorsT } from '../../../../shared/types/types';
import { FavoritesContext } from '../../../../shared/context/favorite.context';
import { SelectedColorContext, SelectedColorDispatchContext } from '../../../../shared/context/selectedColor.context';
import { useNavigate, useParams } from 'react-router-dom';

interface CdtCarouselContainerProps {
  authoredData: AuthoredDataT;
  colors: ColorsT[];
  detailsElement: HTMLDivElement | null;
}

const findColorIndex = (haystack: CartAPIFavoriteT[], needle: ColorsT | null) => {
  return haystack.findIndex((hay) => hay.mdmId === needle?.mdmId || hay.mdmColorId === needle?.mdmId);
};

const findColor = (haystack: ColorsT[], needle: CartAPIFavoriteT | null) => {
  const found = haystack.find((hay) => hay.mdmId === needle?.mdmId || hay.mdmId === needle?.mdmColorId);
  if (!found) return null;
  return found;
};

const CdtCarouselBackgroundContainer: React.FC<CdtCarouselContainerProps> = ({
  authoredData,
  colors,
  detailsElement,
}) => {
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [carouselHeight, setCarouselHeight] = useState(0);
  const favorites = useContext(FavoritesContext);
  const selectedColor = useContext(SelectedColorContext);
  const selectedColorDispatch = useContext(SelectedColorDispatchContext);
  const [colorsForCarousal, setColorsForCarousal] = useState<Array<ColorsT | null>>([]);
  const navigate = useNavigate();
  const params = useParams();

  const synchronizeSelected = useCallback(
    (index: number) => {
      if (selectedColorDispatch) {
        const found = findColor(colors, favorites?.[index]);
        if (found) {
          selectedColorDispatch({ type: 'ADD_SELECTED_COLOR', color: found });
          if (params.mdmId) {
            navigate(
              `/${authoredData?.colorDetailTitle?.toLowerCase().replaceAll(' ', '-')}/${params.projectName}/${
                found.mdmId
              }`,
            );
          }
        }
      }
    },
    [
      authoredData?.colorDetailTitle,
      colors,
      favorites,
      navigate,
      params.mdmId,
      params.projectName,
      selectedColorDispatch,
    ],
  );

  useEffect(() => {
    if (favorites.length > 0) {
      const favoritesToColors = favorites.map((favorite) => findColor(colors, favorite));
      if (selectedColor && findColorIndex(favorites, selectedColor) === -1) {
        setColorsForCarousal([selectedColor]);
      } else {
        if (!selectedColor && selectedColorDispatch) {
          selectedColorDispatch({ type: 'ADD_SELECTED_COLOR', color: findColor(colors, favorites[0]) });
        }
        setColorsForCarousal(favoritesToColors);
      }
    } else {
      if (selectedColor) {
        setColorsForCarousal([selectedColor]);
      } else {
        setColorsForCarousal([]);
      }
    }
  }, [colors, favorites, selectedColor, selectedColorDispatch]);

  const handleWindowResize = () => {
    setCarouselWidth(carouselRef.current?.offsetWidth ?? 0);
    setCarouselHeight(carouselRef.current?.offsetHeight ?? 0);
  };

  const init = useCallback(() => {
    const image = carouselRef.current?.querySelector('img');
    if (image && (carouselRef.current?.offsetWidth ?? carouselRef.current?.offsetHeight ?? 0) > 0) {
      setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
    } else {
      setTimeout(() => init(), 100);
    }
  }, []);

  useEffect(() => {
    if (detailsElement && carouselRef.current && carouselWidth && carouselHeight) {
      // should we move this into a central variable
      if (carouselWidth > 799) {
        detailsElement.style.height = `${carouselHeight}px`;
        setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
      } else {
        detailsElement.style.removeProperty('height');
      }
    } else {
      init();
    }
  }, [carouselHeight, carouselWidth, detailsElement, init]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <CdtCarousel
      virtual={false}
      extra={findColorIndex(favorites, selectedColor) === -1}
      synchronize={synchronizeSelected}
      slideIndexToShow={findColorIndex(favorites, selectedColor)}
      ref={carouselRef}
    >
      {colorsForCarousal?.length > 0 ? (
        colorsForCarousal?.map((color, index) => {
          return <>{color ? <CdtColoredProject authoredData={authoredData} color={color} key={index} /> : null}</>;
        })
      ) : !selectedColor ? (
        <CdtColoredProject authoredData={authoredData} color={null} />
      ) : null}
    </CdtCarousel>
  );
};

export default CdtCarouselBackgroundContainer;
