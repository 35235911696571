import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { GetDataResource } from '../../../../shared/hooks/use-get-data';
import type { AuthoredDataT, ColorDataT, ProductT } from '../../../../shared/types/types';
import CdtColorBar from '../cdt-color-bar/cdt-color-bar';
import CdtColorDetails from './cdt-color-details';
import { SelectedColorContext, SelectedColorDispatchContext } from '../../../../shared/context/selectedColor.context';
import CdtCarouselBackgroundContainer from '../cdt-carousel/cdt-carousel-background-container';

const CdtColorDetailsContainer: React.FC = (): ReactElement => {
  const [detailsElement, setDetailsElement] = useState<HTMLDivElement | null>(null);
  const detailsRef = useRef<HTMLDivElement | null>(null);
  const [[authoredDataResource], [colorDataResource], [productDataResource]] = useLoaderData() as [
    [GetDataResource<AuthoredDataT> | null, AbortController],
    [GetDataResource<ColorDataT> | null, AbortController],
    [GetDataResource<ProductT[]> | null, AbortController],
  ];

  const authoredData = authoredDataResource?.read();
  const colorData = colorDataResource?.read();
  const productData = productDataResource?.read();
  const selectedColor = useContext(SelectedColorContext);
  const dispatchSelectedColor = useContext(SelectedColorDispatchContext);
  const params = useParams();

  useEffect(() => {
    if ((!selectedColor || selectedColor.mdmId !== params.mdmId) && dispatchSelectedColor) {
      const found = colorData?.colors.find((color) => color.mdmId === params.mdmId);
      if (found) {
        dispatchSelectedColor({ type: 'ADD_SELECTED_COLOR', color: found });
      }
    }
  }, [colorData?.colors, dispatchSelectedColor, params.mdmId, selectedColor]);

  const init = useCallback(() => {
    if (detailsRef.current) {
      setDetailsElement(detailsRef.current);
    } else {
      setTimeout(() => init(), 100);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  if (!authoredData || !colorData || !productData || !selectedColor) return <></>;

  return (
    <div className='cmp-color-demonstration-tool'>
      <CdtColorBar authoredData={authoredData} colors={colorData.colors} />
      <CdtColorDetails
        authoredData={authoredData}
        colors={colorData.colors}
        productData={productData}
        settingsData={{ channelId: colorData.channelId, localeId: colorData.localeId }}
        ref={detailsRef}
      />
      <CdtCarouselBackgroundContainer
        authoredData={authoredData}
        colors={colorData.colors}
        detailsElement={detailsElement}
      />
    </div>
  );
};

export default CdtColorDetailsContainer;
